import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SET_NAV_BAR_CHANGE } from "../../redux/actions/types";
import "./NavbarItem.css";
function NavbarItem({ url, lib }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { opendnavbar } = useSelector((state) => state.general);

  return (
    <li>
      <Link
        onClick={() => {
          var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
          if (opendnavbar === false) {
            root.setAttribute(
              "class",
              "mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
            );
          } else {
            root.removeAttribute("class");
          }
          dispatch({
            type: SET_NAV_BAR_CHANGE,
          });
        }}
        to={url}
        className={
          url.toUpperCase() === location.pathname.toUpperCase()
            ? "Selected"
            : ""
        }
      >
        {lib}
      </Link>
    </li>
  );
}
export default NavbarItem;
