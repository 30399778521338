import { Component, useState } from "react";
import ConfirmeSendMoney from "./ConfirmeSendMoney";
import ReactDOM from "react-dom";
import store from "../../redux/store";
import { Provider } from "react-redux";
import axios from "axios";
import ListUsersSubUser from "./ListUsersSubUser";
import ConfirmBlock from "./ConfirmeBlock";
import Confirmepassword from "./Confirmepassword";

class ListUsersitem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      subUser: [],
      isexpend: false,
    };
    this.LoadingSub_User = this.LoadingSub_User.bind(this);
    this.Update_Sub_USer = this.Update_Sub_USer.bind(this);
    this.sendmony = this.sendmony.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.blockedunblocked = this.blockedunblocked.bind(this);
  }
  async LoadingSub_User() {
    if (this.state.isexpend === true) {
      this.setState({
        isexpend: !this.state.isexpend,
      });

      return;
    }
    this.setState({
      isexpend: !this.state.isexpend,
    });
    const user = this.props.user;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_URL_API}` + "listsubusersv2",
        {
          token: JSON.parse(localStorage.getItem("token")),
          iduser: user.id,
        }
      );
      this.setState({
        subUser: result.data.data,
      });
    } catch (error) {}
  }
  Update_Sub_USer(data) {
    console.log("adazdaz");
    const newTodos = [...this.state.subUser];
    const indexitem = this.state.subUser.findIndex(
      (item) => item.id === data.id
    );
    newTodos[indexitem] = data;
    this.setState({ subUser: newTodos });
  }
  sendmony(typeselect) {
    if (this.state.amount === "") {
      return;
    }
    if (parseFloat(this.state.amount) <= 0) {
      return;
    }
    ReactDOM.render(
      <Provider store={store}>
        <ConfirmeSendMoney
          username={this.props.user.username}
          userid={this.props.user.id}
          typesend={typeselect}
          amount={this.state.amount}
        />
      </Provider>,
      document.getElementById("confirmtransaction")
    );
    this.setState({ amount: "" });
  }
  blockedunblocked() {
    ReactDOM.render(
      <Provider store={store}>
        <ConfirmBlock
          username={this.props.user.username}
          userid={this.props.user.id}
          amount={this.state.amount}
          status={this.props.user.status}
        />
      </Provider>,
      document.getElementById("confirmtransaction")
    );
  }
  changePassword() {
    ReactDOM.render(
      <Provider store={store}>
        <Confirmepassword
          username={this.props.user.username}
          userid={this.props.user.id}
          amount={this.state.amount}
        />
      </Provider>,
      document.getElementById("confirmtransaction")
    );
  }
  render() {
    const user = this.props.user;
    return (
      <>
        <div
          className="nvs-AccountListTable-header nvs-row-level-0"
          ng-show="vmAccountTreeTable.ownerId === vmAccountTreeTable.reports.users[vmAccountTreeTable.ownerId].id"
          aria-hidden="false"
        >
          <div className="nvs-AccountListTable-header-cell sub-level0">
            {user.typeuser < 4 ? (
              <span
                onClick={this.LoadingSub_User}
                aria-hidden="true"
                className={
                  this.state.isexpend === true
                    ? "ng-scope nvs-AccountListTable-header--cell-close"
                    : "ng-scope nvs-AccountListTable-header--cell-open"
                }
                role="button"
                tabindex="0"
              ></span>
            ) : null}
          </div>
          <div className="nvs-AccountListTable-header-cell symbol-labels ng-binding">
            L0
          </div>
          <div
            className="nvs-AccountListTable-header-cell symbol-labels ng-isolate-scope"
            nvs-user-symbol=""
            typeof="vmAccountTreeTable.reports.users[vmAccountTreeTable.ownerId]"
          >
            {user.typeuser === 5 ? (
              <span
                uib-tooltip="Joueur"
                class="fa user-type-icons icon-user3 ng-scope"
              ></span>
            ) : user.typeuser === 3 ? (
              <span
                uib-tooltip="Boutique"
                className="fa user-type-icons icon-shop ng-scope"
              ></span>
            ) : user.typeuser === 2 ? (
              <span
                uib-tooltip="Boutique"
                className="fa user-type-icons icon-admin ng-scope"
              ></span>
            ) : user.typeuser === 1 ? (
              <span
                uib-tooltip="Boutique"
                className="fa user-type-icons icon-superadmin ng-scope"
              ></span>
            ) : null}
          </div>
          <div className="nvs-AccountListTable-header-cell ng-binding">
            {user.username}
          </div>
          <div
            className="nvs-AccountListTable-header-cell"
            ng-className="{'nvs-negative-number':vmAccountTreeTable.reports.users[vmAccountTreeTable.ownerId].report.balance<0}"
          >
            <span
              currency-format=""
              currency={user.balance}
              className="ng-isolate-scope"
            >
              TND {user.balance}
            </span>
          </div>
          <div
            className="nvs-AccountListTable-header-cell"
            ng-className="{'nvs-negative-number':vmAccountTreeTable.reports.users[vmAccountTreeTable.ownerId].report.credit_limit<0}"
          >
            <span
              currency-format=""
              currency={user.balancebonus}
              className="ng-isolate-scope"
            >
              TND {user.balancebonus}
            </span>
          </div>
          <div className="nvs-AccountListTable-header-cell">
            <span
              translate="enabled"
              className={
                user.status === 1
                  ? "enabled ng-scope ng-isolate-scope"
                  : "disabled ng-scope ng-isolate-scope"
              }
            >
              {user.status === 1 ? "enabled" : "disabled"}
            </span>
          </div>
          <div
            className="nvs-AccountListTable-header-cell"
            style={{ width: "16%" }}
          >
            {user.status === 1 ? (
              <div
                className="nvs-AffiliateTransferTable-body--btn-wrapper ng-scope"
                ng-if="vmAccountTreeTable.reports.users[userId].status != 0"
              >
                <button
                  onClick={() => this.sendmony(1)}
                  className="nvs-AffiliateTransferTable-body--transfer-withdraw"
                  uib-tooltip="Retrait"
                  ng-click="vmAccountTreeTable.amountTransferData(vmAccountTreeTable.transferAmounts[userId],userId,vmAccountTreeTable.reports.users[userId].username, 'withdraw')"
                >
                  <span className="nvs-AffiliateTransferTable-body--transfer-withdraw-icon"></span>
                </button>{" "}
                <input
                  type="number"
                  className="nvs-AffiliateTransferTable-body--transfer-value ng-valid ng-isolate-scope ng-dirty ng-touched ng-empty"
                  value={this.state.amount}
                  onChange={(e) => this.setState({ amount: e.target.value })}
                  placeholder="Type de montant"
                  aria-invalid="false"
                />{" "}
                <button
                  onClick={() => this.sendmony(0)}
                  className="nvs-AffiliateTransferTable-body--transfer-deposit"
                  uib-tooltip="Dépôt"
                  ng-click="vmAccountTreeTable.amountTransferData(vmAccountTreeTable.transferAmounts[userId],userId,vmAccountTreeTable.reports.users[userId].username, 'deposit')"
                >
                  <span className="nvs-AffiliateTransferTable-body--transfer-deposit-icon"></span>
                </button>
              </div>
            ) : null}
          </div>
          <div
            className="nvs-AccountListTable-header-cell actions ng-scope"
            ng-if="vmAccountTreeTable.reports.users[vmAccountTreeTable.ownerId].children_count > 0"
          >
            <button
              onClick={this.changePassword}
              className="nvs-AccountListTable-header-cell--action-btn editPassword-btn"
              ng-click="vmAccountTreeTable.changePassword(userId)"
            >
              <i className="fa fa-key"></i>
            </button>{" "}
            <button
              onClick={this.blockedunblocked}
              className="nvs-AccountListTable-header-cell--action-btn cancel-user-btn"
              ng-disabled="vmAccountTreeTable.reports.users[userId].status == 0"
              ng-click="vmAccountTreeTable.disableUser(userId)"
            >
              <i className="fa fa-user-times"></i>
            </button>
          </div>
        </div>

        {this.state.isexpend === true && this.state.subUser.length > 0 ? (
          <div
            ng-if="vmAccountTreeTable.reports.users[vmAccountTreeTable.ownerId].isOpen"
            className="ng-scope"
          >
            {this.state.subUser.map((item) => {
              return (
                <ListUsersSubUser
                  user={item}
                  level={1}
                  Update_Sub_USerF={this.Update_Sub_USer}
                />
              );
            })}
          </div>
        ) : null}
      </>
    );
  }
}
export default ListUsersitem;
