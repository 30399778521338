import axios from "axios";
import md5 from "react-native-md5";
import moment from "moment";
import { GET_HISTORIQUE_LIST } from "./types";
import { toastr } from "react-redux-toastr";

export const getHistorique = () => async (dispatch, getState) => {
  const paramfilter = getState();
  if (paramfilter.Historiquetransaction.isloading === false) {
    return;
  }
  const username = paramfilter.Historiquetransaction.username;
  const datefrom = moment(paramfilter.Historiquetransaction.date_start).format(
    "YYYY-MM-DD"
  );
  const dateto = moment(paramfilter.Historiquetransaction.date_end).format(
    "YYYY-MM-DD"
  );
  const hoursfrom = moment(paramfilter.Historiquetransaction.date_start).format(
    "HH:mm"
  );
  const hoursto = moment(paramfilter.Historiquetransaction.date_end).format(
    "HH:mm"
  );
  const timezone = new Date().getTimezoneOffset();
  const coutrow = paramfilter.Historiquetransaction.Count_Page;
  const typehist = paramfilter.Historiquetransaction.type_transaction;
  var select_page = paramfilter.Historiquetransaction.Page_Selected;
  const key = process.env.REACT_APP_MD_CR_A;
  const crypt = md5.hex_md5(
    JSON.parse(localStorage.getItem("token")) +
      username +
      moment(datefrom).format("YYYY-MM-DD") +
      moment(dateto).format("YYYY-MM-DD") +
      hoursfrom +
      hoursto +
      timezone +
      select_page +
      coutrow +
      typehist +
      key
  );

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_URL_API}` + "historiquetv1",
      {
        token: JSON.parse(localStorage.getItem("token")),
        UserName: username,
        datefrom: moment(datefrom).format("YYYY-MM-DD"),
        dateto: moment(dateto).format("YYYY-MM-DD"),
        time_from: hoursfrom,
        time_to: hoursto,
        time_region: timezone,
        page_num: select_page,
        pagecount: coutrow,
        type_hist: typehist,
        sig: crypt,
      }
    );
    dispatch({
      type: GET_HISTORIQUE_LIST,
      payload: result.data,
    });
  } catch (error) {
    toastr.error("Error connection");

    dispatch({
      type: GET_HISTORIQUE_LIST,
      payload: [],
    });
  }
};
export const getHistoriquefinance = () => async (dispatch, getState) => {
  const paramfilter = getState();
  if (paramfilter.Historiquetransaction.isloading === false) {
    return;
  }
  const username = paramfilter.Historiquetransaction.username;
  const datefrom = moment(paramfilter.Historiquetransaction.date_start).format(
    "YYYY-MM-DD"
  );
  const dateto = moment(paramfilter.Historiquetransaction.date_end).format(
    "YYYY-MM-DD"
  );
  const hoursfrom = moment(paramfilter.Historiquetransaction.date_start).format(
    "HH:mm"
  );
  const hoursto = moment(paramfilter.Historiquetransaction.date_end).format(
    "HH:mm"
  );
  const timezone = new Date().getTimezoneOffset();
  const coutrow = paramfilter.Historiquetransaction.Count_Page;
  const typehist = paramfilter.Historiquetransaction.type_transaction;
  var select_page = paramfilter.Historiquetransaction.Page_Selected;
  const key = process.env.REACT_APP_MD_CR_A;
  const crypt = md5.hex_md5(
    JSON.parse(localStorage.getItem("token")) +
      username +
      moment(datefrom).format("YYYY-MM-DD") +
      moment(dateto).format("YYYY-MM-DD") +
      hoursfrom +
      hoursto +
      timezone +
      select_page +
      coutrow +
      typehist +
      key
  );

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_URL_API}` + "historiquetfinancev1",
      {
        token: JSON.parse(localStorage.getItem("token")),
        UserName: username,
        datefrom: moment(datefrom).format("YYYY-MM-DD"),
        dateto: moment(dateto).format("YYYY-MM-DD"),
        time_from: hoursfrom,
        time_to: hoursto,
        time_region: timezone,
        page_num: select_page,
        pagecount: coutrow,
        type_hist: typehist,
        sig: crypt,
      }
    );
    dispatch({
      type: GET_HISTORIQUE_LIST,
      payload: result.data,
    });
  } catch (error) {
    toastr.error("Error connection");

    dispatch({
      type: GET_HISTORIQUE_LIST,
      payload: [],
    });
  }
};
