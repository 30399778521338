import axios from "axios";
import ReactDOM from "react-dom";
import md5 from "react-native-md5";
import { useDispatch } from "react-redux";
import { UPDATE_LIST_USER_BYID } from "../../redux/actions/types";
import { toastr } from "react-redux-toastr";

function ConfirmBlock(props) {
  const dispatch = useDispatch();
  const { userid, username, status } = props;
  async function confirmblocked() {
    const username1 = userid;
    const key = process.env.REACT_APP_MD_CR_A;
    const crypt = md5.hex_md5(
      JSON.parse(localStorage.getItem("token")) + username1 + key
    );
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_URL_API}` + "blockuser",
        {
          token: JSON.parse(localStorage.getItem("token")),
          userid: username1,
          sig: crypt,
        }
      );
      if (result.data.status === 200) {
        if (props.Update_Sub_USer) {
          props.Update_Sub_USer(result.data.data);
        } else {
          dispatch({ type: UPDATE_LIST_USER_BYID, payload: result.data.data });
        }
        toastr.success("success", "Blocked user");

        ReactDOM.unmountComponentAtNode(
          document.getElementById("confirmtransaction")
        );
      } else {
        toastr.error("error", "Block user");

        /*  ReactDOM.unmountComponentAtNode(
          document.getElementById("confirmtransaction")
        );*/
      }
    } catch (error) {
      toastr.error("error Block user");
    }
  }
  const cancelblocked = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("confirmtransaction")
    );
  };
  return (
    <div
      modal-render="true"
      tabindex="-1"
      role="dialog"
      class="modal fade ng-isolate-scope in"
      uib-modal-animation-class="fade"
      modal-in-class="in"
      uib-modal-window="modal-window"
      size="lg"
      index="0"
      animate="animate"
      modal-animation="true"
      style={{ zIndex: "1050", display: "block" }}
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content" uib-modal-transclude="">
          <div
            nvs-disable-user-form=""
            close-popup="closePopup(data)"
            user="user"
            class="ng-scope ng-isolate-scope"
          >
            <div id="nvs-AccountList-disableUserContainer" class="modal-body">
              <h3
                class="nvs-AccountList-disableUser-title ng-isolate-scope"
                ng-translate=""
                translate="disable_user"
              >
                {status === 1
                  ? "Désactiver l'utilisateur"
                  : "Activer l'utilisateur"}
              </h3>
              <div class="nvs-AccountList-disableUser-body">
                <label
                  class="nvs-AccountList-disableUser-body-label ng-isolate-scope"
                  ng-translate=""
                  translate="are_you_sure_disable_user"
                >
                  Êtes-vous sûr de vouloir désactiver cet utilisateur?
                </label>
              </div>
              <div class="nvs-AccountList-disableUser-body">
                <div class="nvs-AccountList-disableUser-body-buttons">
                  <button
                    onClick={confirmblocked}
                    class="nvs-AccountList-disableUser-body-save-btn"
                    ng-click="vmDisableUserForm.disable()"
                  >
                    <span class="save-btn-icon"></span>{" "}
                    <span
                      ng-translate=""
                      translate="disable"
                      class="ng-isolate-scope"
                    >
                      {status === 1 ? "Désactiver" : "Activer"}
                    </span>
                  </button>
                </div>
                <div class="nvs-AccountList-disableUser-body-buttons">
                  <button
                    onClick={cancelblocked}
                    class="nvs-AccountList-disableUser-body-close-btn"
                    ng-click="vmDisableUserForm.close()"
                  >
                    <span class="close-btn-icon"></span>{" "}
                    <span
                      ng-translate=""
                      translate="close"
                      class="ng-isolate-scope"
                    >
                      Fermer
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ConfirmBlock;
