import React, { useEffect, useState } from "react";
import "./LoginForm.css";
import md5 from "react-native-md5";
import { useDispatch, useSelector } from "react-redux";
import { getUSerDetails } from "../../redux/actions/general";
import { Link } from "react-router-dom";
import { GET_USER_DETAILS } from "../../redux/actions/types";
import { useTranslation } from "react-i18next";
function LoginForm() {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [isopendbalance, setisopendbalance] = useState(false);
  const [isopenuser, setisopenuser] = useState(false);
  const dispatch = useDispatch();
  const [isloadinglogin, setisloadinglogin] = useState(false);
  const { t } = useTranslation();
  const [error, seterror] = useState("");
  const { islogin, userDetails } = useSelector((state) => state.general);
  const loginUser = () => {
    const key = process.env.REACT_APP_MD_CR_A;
    const crypt = md5.hex_md5(username + password + key);
    fetch(process.env.REACT_APP_URL_API + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
        sig: crypt,
      }),
    })
      .then((data) => data.json())
      .then((token) => {
        if (token.status === 400) {
          //error message
          seterror("ErrorLogin");
        } else {
          seterror("");
          setisloadinglogin(true);
          localStorage.setItem("token", JSON.stringify(token.token));
          dispatch(getUSerDetails());
        }
      });
  };
  if (islogin === true) {
    return (
      <div class="col-md-8 col-12 d-flex justify-content-lg-end justify-content-end hidden-md-down">
        <div class={"btn-group user-hud"}>
          <div
            class={
              isopendbalance
                ? "btn-group user-hud-balance show"
                : "btn-group user-hud-balance"
            }
          >
            <button
              id="user-hud-balance-btn"
              type="button"
              class="btn btn-white-outline dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              onClick={() => setisopendbalance(!isopendbalance)}
              aria-expanded={isopendbalance ? "true" : "false"}
            >
              Cash:
              <span class="js-balance-cash">
                {userDetails.Currency} {userDetails.Balance.toFixed(3)}
              </span>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="user-hud-balance-btn"
            >
              <li class="dropdown-item">
                Sports Bonus:{" "}
                <span class="js-sports-bonus">
                  {userDetails.Currency} {"0.000"}
                </span>
              </li>
              <li class="dropdown-item">
                Casino Bonus:{" "}
                <span class="js-casino-bonus">
                  {userDetails.Currency} {userDetails.bonus_balance.toFixed(3)}
                </span>
              </li>
            </div>
          </div>

          <div class={isopenuser ? "btn-group show" : "btn-group"}>
            <button
              id="user-account-menu"
              type="button"
              class="btn btn-white-outline dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              onClick={() => setisopenuser(!isopenuser)}
              aria-expanded={isopenuser ? "true" : "false"}
            >
              <i class="fa fa-user mr-1"></i>
              <span class="js-username">{userDetails.username}</span>
            </button>

            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="user-account-menu"
            >
              <Link to="/account" class="dropdown-item " title="My account">
                {t("account.part54")}{" "}
              </Link>
              <a
                onClick={() => {
                  setisloadinglogin(false);
                  dispatch({
                    type: GET_USER_DETAILS,
                    payload: { status: 400 },
                  });
                }}
                class="dropdown-item btn-logout"
                title="Logout"
              >
                {t("account.part55")}{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="form-inline form-login flex-lg-nowrap">
      <div className="input-group on-dark mr-lg-2 mb-lg-0 mb-2">
        <input
          className="form-control login-username"
          placeholder="Username"
          onChange={(text) => setUsername(text.target.value)}
        />
      </div>

      <div className="input-group on-dark mr-lg-2 mb-lg-0 mb-2">
        <input
          type="password"
          className="form-control login-password"
          placeholder="Password"
          onChange={(text) => setPassword(text.target.value)}
        />
        <span
          className="forgot-password-desktop"
          style={error === "" ? { display: "none" } : {}}
        >
          {t("error_message.Error_4")}
        </span>
      </div>

      <button
        className={
          isloadinglogin === false
            ? "btn btn-secondary-fancy btn-register"
            : "btn btn-primary mr-sm-2 mb-sm-0 btn-login-form btn-loading"
        }
        title=""
        onClick={() => loginUser()}
      >
        {t("account.part56")}
      </button>
    </div>
  );
}
export default LoginForm;
