import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getrevenu } from "../../redux/actions/Revenus";
import {
  INIT_REVENU,
  UPDATE_REVENU_DATE_END,
  UPDATE_REVENU_DATE_START,
  UPDATE_REVENU_TYPE,
} from "../../redux/actions/types";
import "./Historiqueparis.css";
import "./Historiquetransaction.css";
function Revenus() {
  const { date_start, date_end, type_report, listh } = useSelector(
    (state) => state.Revenu
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: INIT_REVENU });
    dispatch(getrevenu());
  }, []);
  return (
    <div ui-view="content" className="ng-scope">
      <section id="transactionHistoryContainer" className="ng-scope">
        <h4
          ng-translate=""
          translate="transaction_history"
          className="ng-isolate-scope"
        >
          {t("account.part10")}
        </h4>
        <nvs-transaction-history-filter className="ng-isolate-scope">
          <section id="transactionHistoryFilterContainer">
            <div
              name="transactionHistoryFilterForm"
              novalidate=""
              className="ng-pristine ng-valid ng-valid-datetime"
            >
              <div className="input-group-container">
                <div className="input-group-item">
                  <label
                    for=""
                    ng-translate=""
                    translate="date_range"
                    className="ng-isolate-scope"
                  >
                    Plage de dates
                  </label>
                  <nvs-date-range-picker
                    date="vmTransactionHistoryFilter.dateTime"
                    className="ng-isolate-scope"
                  >
                    <div className="input-group-container mb-0 date-range-picker-container">
                      <div className="input-group-item">
                        <DatePicker
                          className="form-input ng-valid ng-isolate-scope ng-not-empty ng-valid-datetime ng-touched ng-dirty"
                          selected={date_start}
                          onChange={(date) =>
                            dispatch({
                              type: UPDATE_REVENU_DATE_START,
                              payload: date,
                            })
                          }
                          selectsStart
                          startDate={date_start}
                          endDate={date_end}
                          showTimeInput
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <div className="input-group-item">
                        <DatePicker
                          className="form-input ng-valid ng-isolate-scope ng-not-empty ng-valid-datetime ng-touched ng-dirty"
                          selected={date_end}
                          onChange={(date) =>
                            dispatch({
                              type: UPDATE_REVENU_DATE_END,
                              payload: date,
                            })
                          }
                          selectsStart
                          startDate={date_start}
                          endDate={date_end}
                          showTimeInput
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </nvs-date-range-picker>
                </div>
                <div className="input-group-item flex-basis-50">
                  <label
                    ng-translate=""
                    translate="transaction_type"
                    className="ng-isolate-scope"
                  >
                    Type de Report
                  </label>
                  <div className="form-select">
                    <select
                      value={type_report}
                      onChange={(event) =>
                        dispatch({
                          type: UPDATE_REVENU_TYPE,
                          payload: event.target.value,
                        })
                      }
                      className="form-input ng-pristine ng-valid ng-not-empty ng-touched"
                      ng-model="vmTransactionHistoryFilter.data.transaction"
                      ng-options="type.value as type.name | translate for type in vmTransactionHistoryFilter.transactions"
                      aria-invalid="false"
                    >
                      <option
                        label="Report Provider"
                        value="1"
                        selected="selected"
                      >
                        Report Provider
                      </option>
                      <option label="Report utilisateur" value="2">
                        Report utilisateur
                      </option>
                    </select>
                  </div>
                </div>{" "}
                <div className="input-group-item inline">
                  <button
                    onClick={() => {
                      dispatch(getrevenu());
                    }}
                    className="button button-primary"
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>{" "}
                    <span ng-translate="">Recherche</span>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </nvs-transaction-history-filter>
        <nvs-transaction-history-list className="ng-isolate-scope">
          <section
            id="transactionHistoryListContainer"
            style={{ minHeight: "150px" }}
          >
            {listh.length > 0 ? (
              <div aria-hidden="false" className="">
                <table className="custom-table with-margin zebra-row">
                  <thead ng-className="{'virtual-games': vmTransactionHistoryList.data.product==='virtual'}">
                    <tr>
                      <th role="button" tabindex="0" style={{ width: "25%" }}>
                        <span>
                          {type_report == 1 ? "Provider" : "Username"}
                        </span>
                      </th>
                      <th
                        style={{ width: "25%" }}
                        ng-click="vmTransactionHistoryList.changeSorting('created_at')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="date"
                          className="ng-isolate-scope"
                        >
                          Bet
                        </span>{" "}
                      </th>
                      <th role="button" tabindex="0" style={{ width: "25%" }}>
                        <span
                          ng-translate=""
                          translate="description"
                          className="ng-isolate-scope"
                        >
                          Win
                        </span>{" "}
                      </th>
                      <th
                        style={{ width: "25%" }}
                        ng-click="vmTransactionHistoryList.changeSorting('balance_before')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="old_balance"
                          className="ng-isolate-scope"
                        >
                          Revenu
                        </span>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listh.map((item) => (
                      <tr
                        ng-className="{'virtual-games': vmTransactionHistoryList.data.product==='virtual'}"
                        ng-repeat="transaction in vmTransactionHistoryList.list.transaction | orderBy: vmTransactionHistoryList.sort.sortingOrder:vmTransactionHistoryList.sort.reverse"
                        className="ng-scope"
                      >
                        <td style={{ width: "25%", textAlign: "left" }}>
                          <a className="ng-binding">
                            {type_report === 1 ? item.Provider : item.username}
                          </a>
                        </td>
                        <td
                          style={{ width: "25%", textAlign: "left" }}
                          currency-format=""
                          currency="0.1"
                          className="ng-isolate-scope"
                        >
                          {item.bet.toFixed(3)}
                        </td>
                        <td
                          style={{ width: "25%", textAlign: "left" }}
                          currency-format=""
                          currency="0.1"
                          className="ng-isolate-scope"
                        >
                          {item.win.toFixed(3)}
                        </td>
                        <td style={{ width: "25%", textAlign: "left" }}>
                          <span
                            style={item.revenu < 0 ? { color: "red" } : {}}
                            currency-format=""
                            currency="0.02"
                            className="ng-isolate-scope"
                          >
                            {item.revenu.toFixed(3)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ul className="table-total-field">
                  <li>
                    <span
                      ng-translate=""
                      translate="Total"
                      className="ng-isolate-scope"
                    >
                      Total
                    </span>
                  </li>
                  <li>
                    <span
                      ng-translate=""
                      translate="deposit"
                      className="ng-isolate-scope"
                    >
                      bet
                    </span>{" "}
                    :{" "}
                    <span
                      currency-format=""
                      currency="0"
                      className="ng-isolate-scope"
                    >
                      TND{" "}
                      {listh.reduce((a, v) => (a = a + v.bet), 0).toFixed(3)}
                    </span>
                  </li>
                  <li>
                    <span
                      ng-translate=""
                      translate="withdraw"
                      className="ng-isolate-scope"
                    >
                      Total Win
                    </span>{" "}
                    :{" "}
                    <span
                      currency-format=""
                      currency="0.10000000149011612"
                      className="ng-isolate-scope"
                    >
                      TND{" "}
                      {listh.reduce((a, v) => (a = a + v.win), 0).toFixed(3)}
                    </span>
                  </li>
                  <li>
                    <span>
                      *(
                      <span
                        currency-format=""
                        currency="0"
                        className="ng-isolate-scope"
                      >
                        TND{" "}
                        {listh.reduce((a, v) => (a = a + v.bet), 0).toFixed(3)}
                      </span>{" "}
                      -{" "}
                      <span
                        currency-format=""
                        currency="0.10000000149011612"
                        className="ng-isolate-scope"
                      >
                        TND{" "}
                        {listh.reduce((a, v) => (a = a + v.win), 0).toFixed(3)}
                      </span>
                      ={" "}
                      <span
                        currency-format=""
                        currency="-0.1"
                        className="ng-isolate-scope"
                      >
                        {(
                          listh.reduce((a, v) => (a = a + v.bet), 0) -
                          listh.reduce((a, v) => (a = a + v.win), 0)
                        ).toFixed(3)}
                      </span>
                      )
                    </span>
                  </li>
                  <li></li>
                </ul>
              </div>
            ) : (
              <div
                ng-if="vmTransactionHistoryList.list.transaction.length===0 &amp;&amp; !vmTransactionHistoryList.dataLoadingStatus.isLoading &amp;&amp; vmTransactionHistoryList.dataLoadingStatus.isSearched"
                class="ng-scope"
              >
                <nvs-no-data-info class="ng-isolate-scope">
                  <div class="no-data-information">
                    <div class="nvs-no-data-wrapper">
                      <i class="fa icon-problem fa-5x" aria-hidden="true"></i>
                      <p
                        class="nvs-no-data-info ng-isolate-scope"
                        ng-translate=""
                        translate="_no_available_data"
                      >
                        Pas de données disponibles
                      </p>
                    </div>
                  </div>
                </nvs-no-data-info>
              </div>
            )}
          </section>
        </nvs-transaction-history-list>
      </section>
    </div>
  );
}
export default Revenus;
