import { useDispatch, useSelector } from "react-redux";
import {
  SET_NEW_IS_OPEND_TYPE,
  SET_NEW_PASSWORD,
  SET_NEW_TYPE_USER,
  SET_NEW_USER_NAME,
} from "../../redux/actions/types";
import { InsertNewAccount } from "../../redux/actions/NewAccount";
import "./NewAccount.css";
import { useTranslation } from "react-i18next";
function NewAccount() {
  const {
    username,
    password,
    typeid,
    error_username,
    error_password,
    error_type_id,
    isloading,
    isopend,
  } = useSelector((state) => state.NewAccount);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { userDetails } = useSelector((state) => state.general);

  return (
    <div ui-view="content" className="ng-scope">
      <section id="newUserContainer" className="ng-scope">
        <h3 translate="new_user" className="ng-isolate-scope">
          {t("account.part7")}
        </h3>
        <nvs-new-user-form className="ng-isolate-scope">
          <section id="newUserFormContainer">
            <div
              novalidate=""
              className="ng-pristine ng-invalid ng-invalid-required ng-valid-pattern ng-valid-minlength ng-valid-email"
              aria-hidden="false"
            >
              <div className="input-group-container">
                <div className="input-group-item">
                  <div className={error_username === "" ? "" : "form-error"}>
                    <div>
                      <label
                        ng-translate=""
                        translate="username"
                        className="ng-isolate-scope"
                      >
                        {t("account.part19")}
                      </label>{" "}
                      <i className="fa fa-asterisk" aria-hidden="true"></i>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={username}
                        onChange={(el) =>
                          dispatch({
                            type: SET_NEW_USER_NAME,
                            payload: el.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                      />
                      <p
                        translate="required_username"
                        className={
                          error_username === ""
                            ? "ng-isolate-scope ng-hide"
                            : "ng-isolate-scope"
                        }
                        aria-hidden="true"
                      >
                        {t("error_message.error_1")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="input-group-item">
                  <div className={error_password === "" ? "" : "form-error"}>
                    <div>
                      <label translate="password" className="ng-isolate-scope">
                        {t("account.part22")}
                      </label>{" "}
                      <i className="fa fa-asterisk" aria-hidden="true"></i>
                    </div>
                    <div>
                      <input
                        type="password"
                        value={password}
                        onChange={(el) =>
                          dispatch({
                            type: SET_NEW_PASSWORD,
                            payload: el.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength"
                        required=""
                        aria-invalid="true"
                      />
                      <p
                        translate="required_password"
                        className={
                          error_password === ""
                            ? "ng-isolate-scope ng-hide"
                            : "ng-isolate-scope"
                        }
                        aria-hidden="true"
                      >
                        {t("error_message.error_2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-group-container">
                <div className="input-group-item">
                  <div className={error_type_id === "" ? "" : "form-error"}>
                    <div>
                      <label
                        translate="account_type"
                        className="ng-isolate-scope"
                      >
                        {t("account.part28")}
                      </label>{" "}
                      <i className="fa fa-asterisk" aria-hidden="true"></i>
                    </div>
                    <div className="ui-select-wrap w-100">
                      <div
                        class={
                          isopend === true
                            ? "ui-select-container ui-select-bootstrap dropdown ng-empty ng-invalid ng-invalid-required open"
                            : "ui-select-container ui-select-bootstrap dropdown ng-empty ng-invalid ng-invalid-required"
                        }
                        theme="bootstrap"
                        onkeypress="return false;"
                        role="button"
                        tabindex="0"
                        aria-required="true"
                        aria-invalid="true"
                      >
                        <div
                          onClick={() =>
                            dispatch({
                              type: SET_NEW_IS_OPEND_TYPE,
                              payload: true,
                            })
                          }
                          class={
                            isopend === true
                              ? "nvs-Form-accountType--input-container--select-content ui-select-match ng-scope ng-hide"
                              : "nvs-Form-accountType--input-container--select-content ui-select-match ng-scope"
                          }
                          placeholder="Sélectionner le genre"
                          aria-hidden="false"
                          aria-disabled="false"
                        >
                          <span
                            tabindex="-1"
                            className="btn btn-default form-control ui-select-toggle"
                            aria-label="Select box activate"
                            style={{ outline: "0" }}
                            role="button"
                            aria-disabled="false"
                          >
                            <span
                              ng-show="$select.isEmpty()"
                              className="ui-select-placeholder text-muted ng-binding"
                              aria-hidden="false"
                            >
                              {typeid === -1
                                ? "Sélectionner le genre"
                                : t("user_type.part" + typeid)}
                            </span>{" "}
                            <span
                              className="ui-select-match-text pull-left ng-hide"
                              aria-hidden="true"
                            >
                              <span className="ng-binding ng-scope"></span>
                            </span>{" "}
                            <i
                              className="caret pull-right"
                              role="button"
                              tabindex="0"
                            ></i>{" "}
                            <a
                              aria-label="Select box clear"
                              style={{ marginRight: "10px" }}
                              className="btn btn-xs btn-link pull-right ng-hide"
                              aria-hidden="true"
                            >
                              <i
                                className="glyphicon glyphicon-remove"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span>
                        </div>
                        <span
                          className="ui-select-refreshing glyphicon glyphicon-refresh ui-select-spin ng-hide"
                          aria-hidden="true"
                        ></span>
                        <input
                          type="search"
                          autocomplete="off"
                          tabindex="-1"
                          aria-expanded="true"
                          aria-label="Select box"
                          aria-owns="ui-select-choices-0"
                          class={
                            isopend === true
                              ? "form-control ui-select-search ng-pristine ng-valid ng-empty ng-touched"
                              : "form-control ui-select-search ng-pristine ng-untouched ng-valid ng-empty ng-hide"
                          }
                          placeholder="Sélectionner le genre"
                          aria-hidden="true"
                          aria-invalid="false"
                        />
                        <ul
                          class={
                            isopend === true
                              ? "ui-select-choices ui-select-choices-content ui-select-dropdown dropdown-menu ng-scope"
                              : "ui-select-choices ui-select-choices-content ui-select-dropdown dropdown-menu ng-scope ng-hide"
                          }
                          aria-hidden="true"
                        >
                          {userDetails.type_User <= 0 ? (
                            <li
                              className="ui-select-choices-group"
                              id="ui-select-choices-27"
                            >
                              <div
                                className="divider ng-hide"
                                aria-hidden="true"
                              ></div>
                              <div
                                className="ui-select-choices-group-label dropdown-header ng-binding ng-hide"
                                aria-hidden="true"
                              ></div>
                              <div
                                className="ui-select-choices-row ng-scope active"
                                role="option"
                                tabindex="0"
                                id="ui-select-choices-row-27-0"
                              >
                                <span
                                  onClick={() =>
                                    dispatch({
                                      type: SET_NEW_TYPE_USER,
                                      payload: 1,
                                    })
                                  }
                                  className="ui-select-choices-row-inner"
                                  uis-transclude-append=""
                                >
                                  <span className="ng-binding ng-scope">
                                    {t("user_type.part1")}
                                  </span>
                                </span>
                              </div>
                            </li>
                          ) : null}
                          {userDetails.type_User <= 1 ? (
                            <li
                              onClick={() =>
                                dispatch({
                                  type: SET_NEW_TYPE_USER,
                                  payload: 2,
                                })
                              }
                              className="ui-select-choices-group"
                              id="ui-select-choices-27"
                            >
                              <div
                                className="divider ng-hide"
                                aria-hidden="true"
                              ></div>
                              <div
                                className="ui-select-choices-group-label dropdown-header ng-binding ng-hide"
                                aria-hidden="true"
                              ></div>
                              <div
                                className="ui-select-choices-row ng-scope active"
                                role="option"
                                tabindex="0"
                                id="ui-select-choices-row-27-0"
                              >
                                <span
                                  className="ui-select-choices-row-inner"
                                  uis-transclude-append=""
                                >
                                  <span className="ng-binding ng-scope">
                                    {t("user_type.part2")}
                                  </span>
                                </span>
                              </div>
                            </li>
                          ) : null}
                          {userDetails.type_User <= 2 ? (
                            <li
                              onClick={() =>
                                dispatch({
                                  type: SET_NEW_TYPE_USER,
                                  payload: 3,
                                })
                              }
                              className="ui-select-choices-group"
                              id="ui-select-choices-27"
                            >
                              <div
                                className="divider ng-hide"
                                aria-hidden="true"
                              ></div>
                              <div
                                className="ui-select-choices-group-label dropdown-header ng-binding ng-hide"
                                aria-hidden="true"
                              ></div>
                              <div
                                className="ui-select-choices-row ng-scope active"
                                role="option"
                                tabindex="0"
                                id="ui-select-choices-row-27-0"
                              >
                                <span
                                  className="ui-select-choices-row-inner"
                                  uis-transclude-append=""
                                >
                                  <span className="ng-binding ng-scope">
                                    {t("user_type.part3")}
                                  </span>
                                </span>
                              </div>
                            </li>
                          ) : null}

                          {userDetails.type_User <= 4 ? (
                            <li
                              onClick={() =>
                                dispatch({
                                  type: SET_NEW_TYPE_USER,
                                  payload: 5,
                                })
                              }
                              className="ui-select-choices-group"
                              id="ui-select-choices-27"
                            >
                              <div
                                className="divider ng-hide"
                                aria-hidden="true"
                              ></div>
                              <div
                                className="ui-select-choices-group-label dropdown-header ng-binding ng-hide"
                                aria-hidden="true"
                              ></div>
                              <div
                                className="ui-select-choices-row ng-scope active"
                                role="option"
                                tabindex="0"
                                id="ui-select-choices-row-27-0"
                              >
                                <span
                                  className="ui-select-choices-row-inner"
                                  uis-transclude-append=""
                                >
                                  <span className="ng-binding ng-scope">
                                    {t("user_type.part5")}
                                  </span>
                                </span>
                              </div>
                            </li>
                          ) : null}
                        </ul>
                        <div className="ui-select-no-choice"></div>
                        <ui-select-single></ui-select-single>
                        <input
                          className="ui-select-focusser ui-select-offscreen ng-scope"
                          type="text"
                          id="focusser-0"
                          aria-label="Select box focus"
                          aria-haspopup="true"
                          role="button"
                        />
                      </div>
                      <p
                        translate="required_account_type"
                        className={
                          error_type_id === ""
                            ? "ng-isolate-scope ng-hide"
                            : "ng-isolate-scope"
                        }
                        aria-hidden="true"
                      >
                        {t("error_message.error_3")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="input-group-item" />
              </div>

              <div className="input-group-container">
                <div className="input-group-item">
                  <button
                    onClick={() => dispatch(InsertNewAccount())}
                    type="submit"
                    disabled={isloading === true ? "disabled" : ""}
                    class={
                      isloading === true
                        ? "button button-primary w-100 ng-isolate-scope btn-loading"
                        : "button button-primary w-100 ng-isolate-scope"
                    }
                    translate="Save"
                  >
                    {t("account.part24")}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </nvs-new-user-form>
      </section>
    </div>
  );
}
export default NewAccount;
