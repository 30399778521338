import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getHistorique } from "../../redux/actions/Historiquetransaction";
import {
  INIT_HISTORIQUE_TRANCATION,
  UPDATE_COUNT_HISTORIQUE,
  UPDATE_DATE_END,
  UPDATE_DATE_START,
  UPDATE_HISTORIQUE_SELECt_PAGE,
  UPDATE_HISTORIQUE_TYPE,
  UPDATE_HISTORIQUE_USERNAME,
} from "../../redux/actions/types";
import "./Historiqueparis.css";
import "./Historiquetransaction.css";
function Historiquetransaction() {
  const {
    date_start,
    date_end,
    type_transaction,
    Count_Page,
    listh,
    pagination,
    Page_Selected,
    totalrow,
    username,
    totaldepot,
    totalwidhrow,
  } = useSelector((state) => state.Historiquetransaction);
  const { userDetails } = useSelector((state) => state.general);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: INIT_HISTORIQUE_TRANCATION });
    dispatch(getHistorique());
  }, []);
  return (
    <div ui-view="content" className="ng-scope">
      <section id="transactionHistoryContainer" className="ng-scope">
        <h4
          ng-translate=""
          translate="transaction_history"
          className="ng-isolate-scope"
        >
          Historique des transactions
        </h4>
        <nvs-transaction-history-filter className="ng-isolate-scope">
          <section id="transactionHistoryFilterContainer">
            <div
              name="transactionHistoryFilterForm"
              novalidate=""
              className="ng-pristine ng-valid ng-valid-datetime"
            >
              <div className="input-group-container">
                <div className="input-group-item">
                  <label
                    for=""
                    ng-translate=""
                    translate="date_range"
                    className="ng-isolate-scope"
                  >
                    Plage de dates
                  </label>
                  <nvs-date-range-picker
                    date="vmTransactionHistoryFilter.dateTime"
                    className="ng-isolate-scope"
                  >
                    <div className="input-group-container mb-0 date-range-picker-container">
                      <div className="input-group-item">
                        <DatePicker
                          className="form-input ng-valid ng-isolate-scope ng-not-empty ng-valid-datetime ng-touched ng-dirty"
                          selected={date_start}
                          onChange={(date) =>
                            dispatch({ type: UPDATE_DATE_START, payload: date })
                          }
                          selectsStart
                          startDate={date_start}
                          endDate={date_end}
                          showTimeInput
                          dateFormat="dd/MM/yyyy HH:mm"
                        />
                      </div>
                      <div className="input-group-item">
                        <DatePicker
                          className="form-input ng-valid ng-isolate-scope ng-not-empty ng-valid-datetime ng-touched ng-dirty"
                          selected={date_end}
                          onChange={(date) =>
                            dispatch({ type: UPDATE_DATE_END, payload: date })
                          }
                          selectsStart
                          startDate={date_start}
                          endDate={date_end}
                          showTimeInput
                          dateFormat="dd/MM/yyyy HH:mm"
                        />
                      </div>
                    </div>
                  </nvs-date-range-picker>
                </div>
                <div className="input-group-item flex-basis-50">
                  <label
                    ng-translate=""
                    translate="transaction_type"
                    className="ng-isolate-scope"
                  >
                    Type de transaction
                  </label>
                  <div className="form-select">
                    <select
                      value={type_transaction}
                      onChange={(event) =>
                        dispatch({
                          type: UPDATE_HISTORIQUE_TYPE,
                          payload: event.target.value,
                        })
                      }
                      className="form-input ng-pristine ng-valid ng-not-empty ng-touched"
                      ng-model="vmTransactionHistoryFilter.data.transaction"
                      ng-options="type.value as type.name | translate for type in vmTransactionHistoryFilter.transactions"
                      aria-invalid="false"
                    >
                      <option
                        label="toutes transactions"
                        value="-1"
                        selected="selected"
                      >
                        toutes transactions
                      </option>
                      <option label="Dépôt" value="0">
                        Dépôt
                      </option>
                      <option label="Retrait" value="1">
                        Retrait
                      </option>
                    </select>
                  </div>
                </div>
                <div className="input-group-item flex-basis-50">
                  <label
                    ng-translate=""
                    translate="transaction_id"
                    className="ng-isolate-scope"
                  >
                    Identifiant de transaction
                  </label>
                  <input
                    type="text"
                    className="form-input ng-pristine ng-valid ng-isolate-scope ng-empty ng-touched"
                    ng-placeholder="transaction_id"
                    ng-pattern-restrict="^\d{0,13}(\-\d{0,13})?$"
                    ng-model="vmTransactionHistoryFilter.data.transactionId"
                    placeholder="Identifiant de transaction"
                    aria-invalid="false"
                  />
                </div>
              </div>
              <div className="input-group-container">
                {userDetails.type_User < 5 ? (
                  <div className="input-group-item">
                    <label
                      ng-translate=""
                      translate="Bookmaker Products"
                      className="ng-isolate-scope"
                    >
                      Username
                    </label>
                    <div className="form-select">
                      <input
                        type="text"
                        value={username}
                        onChange={(e) =>
                          dispatch({
                            type: UPDATE_HISTORIQUE_USERNAME,
                            payload: e.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-valid ng-isolate-scope ng-empty ng-touched"
                        ng-placeholder="transaction_id"
                        ng-pattern-restrict="^\d{0,13}(\-\d{0,13})?$"
                        ng-model="vmTransactionHistoryFilter.data.transactionId"
                        placeholder="User name"
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="input-group-item">
                  <label
                    ng-translate=""
                    translate="Bookmaker Products"
                    className="ng-isolate-scope"
                  >
                    Nombre de historique
                  </label>
                  <div className="form-select">
                    <select
                      value={Count_Page}
                      onChange={(event) =>
                        dispatch({
                          type: UPDATE_COUNT_HISTORIQUE,
                          payload: event.target.value,
                        })
                      }
                      className="form-input ng-pristine ng-valid ng-not-empty ng-touched"
                      ng-model="vmTransactionHistoryFilter.data.transaction"
                      ng-options="type.value as type.name | translate for type in vmTransactionHistoryFilter.transactions"
                      aria-invalid="false"
                    >
                      <option label="10" value="10" selected="selected">
                        10{" "}
                      </option>
                      <option label="25" value="25">
                        25
                      </option>
                      <option label="50" value="50">
                        50
                      </option>{" "}
                      <option label="100" value="100">
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div className="input-group-item inline">
                  <button
                    onClick={() => {
                      dispatch({
                        type: UPDATE_HISTORIQUE_SELECt_PAGE,
                        payload: 0,
                      });
                      dispatch(getHistorique());
                    }}
                    className="button button-primary"
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>{" "}
                    <span ng-translate="">Recherche</span>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </nvs-transaction-history-filter>
        <nvs-transaction-history-list className="ng-isolate-scope">
          <section
            id="transactionHistoryListContainer"
            style={{ minHeight: "150px" }}
          >
            {listh.length > 0 ? (
              <div aria-hidden="false" className="">
                <table className="custom-table with-margin zebra-row">
                  <thead ng-className="{'virtual-games': vmTransactionHistoryList.data.product==='virtual'}">
                    <tr>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('id')"
                        role="button"
                        tabindex="0"
                      >
                        <span>#</span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('id')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('created_at')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="date"
                          className="ng-isolate-scope"
                        >
                          Date
                        </span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('created_at')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('balance_before')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="description"
                          className="ng-isolate-scope"
                        >
                          Description
                        </span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('balance_before')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('balance_before')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="old_balance"
                          className="ng-isolate-scope"
                        >
                          Ancien solde
                        </span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('balance_before')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('amount')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="amount"
                          className="ng-isolate-scope"
                        >
                          Montant
                        </span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('amount')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('balance_after')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="new_balance"
                          className="ng-isolate-scope"
                        >
                          Nouveau solde
                        </span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('balance_after')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('transfer_type')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="type"
                          className="ng-isolate-scope"
                        >
                          Type
                        </span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('transfer_type')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('product')"
                        role="button"
                        tabindex="0"
                      >
                        <span
                          ng-translate=""
                          translate="product"
                          className="ng-isolate-scope"
                        >
                          Fournisseur
                        </span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('product')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                      <th
                        ng-click="vmTransactionHistoryList.changeSorting('event_id')"
                        ng-show="vmTransactionHistoryList.data.product==='virtual'"
                        role="button"
                        tabindex="0"
                        aria-hidden="true"
                        className="ng-hide"
                      >
                        <span className="ng-isolate-scope">ID d'événement</span>{" "}
                        <i className="fa fa-sort"></i>
                      </th>
                      <th
                        role="button"
                        tabindex="0"
                        aria-hidden="true"
                        className="ng-hide"
                      >
                        <span
                          ng-translate=""
                          translate="ticket_id"
                          className="ng-isolate-scope"
                        >
                          ID du Ticket
                        </span>{" "}
                        <i
                          ng-className="vmTransactionHistoryList.selectedClass('ticket_id')"
                          className="fa fa-sort"
                        ></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listh.map((item) => (
                      <tr
                        ng-className="{'virtual-games': vmTransactionHistoryList.data.product==='virtual'}"
                        ng-repeat="transaction in vmTransactionHistoryList.list.transaction | orderBy: vmTransactionHistoryList.sort.sortingOrder:vmTransactionHistoryList.sort.reverse"
                        className="ng-scope"
                      >
                        <td>
                          <a
                            ui-sref="transactionHistoryDetail({transactionId:'38117412834'})"
                            className="ng-binding"
                          >
                            #{item.id}
                          </a>
                        </td>
                        <td>
                          <nvs-configure-date-time-for-view
                            date-time="transaction.created_at"
                            className="ng-isolate-scope"
                          >
                            {item.date_cre}
                          </nvs-configure-date-time-for-view>
                        </td>
                        <td>
                          {item.provider === "Sport" ? (
                            <Link
                              to={
                                userDetails.type_User < 5
                                  ? "/account/historique/" + item.remarque
                                  : "/mybets"
                              }
                              translate="All Ways Hot Fruits Bet"
                              className="ng-isolate-scope"
                            >
                              {item.remarque}
                            </Link>
                          ) : (
                            <span
                              ng-translate=""
                              translate="All Ways Hot Fruits Bet"
                              className="ng-isolate-scope"
                            >
                              {item.remarque}
                            </span>
                          )}
                        </td>
                        <td>
                          <span currency-format="" className="ng-isolate-scope">
                            {item.balance_before.toFixed(3)}
                          </span>
                        </td>
                        <td
                          currency-format=""
                          currency="0.1"
                          className="ng-isolate-scope"
                        >
                          {item.amount.toFixed(3)}
                        </td>
                        <td>
                          <span
                            currency-format=""
                            currency="0.02"
                            className="ng-isolate-scope"
                          >
                            {item.balance_after.toFixed(3)}
                          </span>
                        </td>
                        <td>
                          <span
                            className="transaction-withdraw ng-scope ng-isolate-scope"
                            style={
                              item.type === 0
                                ? { color: "rgb(65, 146, 76)" }
                                : { color: "rgb(208, 98, 97)" }
                            }
                          >
                            {t("typetransaction." + item.type)}
                          </span>
                        </td>
                        <td>
                          <span className="ng-binding">{item.provider}</span>
                        </td>
                        <td
                          ng-show="vmTransactionHistoryList.data.product==='virtual'"
                          aria-hidden="true"
                          className="ng-hide"
                        >
                          <span className="ng-binding"></span>
                        </td>
                        <td
                          ng-show="vmTransactionHistoryList.data.product==='virtual'"
                          aria-hidden="true"
                          className="ng-hide"
                        >
                          <span className="ng-binding">30014</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ul className="table-total-field">
                  <li>
                    <span
                      ng-translate=""
                      translate="Total"
                      className="ng-isolate-scope"
                    >
                      Total
                    </span>
                  </li>
                  <li>
                    <span
                      ng-translate=""
                      translate="deposit"
                      className="ng-isolate-scope"
                    >
                      Dépôt
                    </span>{" "}
                    :{" "}
                    <span
                      currency-format=""
                      currency="0"
                      className="ng-isolate-scope"
                    >
                      Cr. {totaldepot.toFixed(3)}
                    </span>
                  </li>
                  <li>
                    <span
                      ng-translate=""
                      translate="withdraw"
                      className="ng-isolate-scope"
                    >
                      Retrait
                    </span>{" "}
                    :{" "}
                    <span
                      currency-format=""
                      currency="0.10000000149011612"
                      className="ng-isolate-scope"
                    >
                      Cr. {totalwidhrow.toFixed(3)}
                    </span>
                  </li>
                  <li>
                    <span>
                      *(
                      <span
                        currency-format=""
                        currency="0"
                        className="ng-isolate-scope"
                      >
                        Cr. {totaldepot.toFixed(3)}
                      </span>{" "}
                      -{" "}
                      <span
                        currency-format=""
                        currency="0.10000000149011612"
                        className="ng-isolate-scope"
                      >
                        Cr. {totalwidhrow.toFixed(3)}
                      </span>
                      ={" "}
                      <span
                        currency-format=""
                        currency="-0.1"
                        className="ng-isolate-scope"
                      >
                        {(totaldepot - totalwidhrow).toFixed(3)}
                      </span>
                      )
                    </span>
                  </li>
                  <li>
                    <span
                      ng-translate=""
                      translate="current_balance"
                      className="ng-isolate-scope"
                    >
                      Solde actuel
                    </span>{" "}
                    :{" "}
                    <span
                      currency-format=""
                      currency="0.02"
                      className="ng-isolate-scope"
                    >
                      Cr. {userDetails.Balance}
                    </span>
                  </li>
                </ul>
                <div
                  class="ng-scope"
                  style={{ display: pagination === true ? "" : "none" }}
                >
                  <ul
                    class="pagination ng-pristine ng-untouched ng-valid ng-isolate-scope ng-not-empty"
                    max-size="5"
                    direction-links="true"
                    boundary-links="true"
                    aria-invalid="false"
                  >
                    <li
                      class={
                        Page_Selected === 0
                          ? "pagination-prev ng-scope disabled"
                          : "pagination-prev ng-scope"
                      }
                      onClick={() => {
                        dispatch({
                          type: UPDATE_HISTORIQUE_SELECt_PAGE,
                          payload: 0,
                        });
                        dispatch(getHistorique());
                      }}
                    >
                      <a class="ng-binding">First</a>
                    </li>

                    <li
                      class={
                        Page_Selected === 0
                          ? "pagination-prev ng-scope disabled"
                          : "pagination-prev ng-scope"
                      }
                    >
                      <a
                        onClick={() => {
                          if (Page_Selected > 0) {
                            dispatch({
                              type: UPDATE_HISTORIQUE_SELECt_PAGE,
                              payload: Page_Selected - 1,
                            });
                            dispatch(getHistorique());
                          }
                        }}
                        class="ng-binding"
                      >
                        Previous
                      </a>
                    </li>
                    {Array.from(
                      Array(Math.ceil(totalrow / Count_Page)).keys()
                    ).map((item) => {
                      if (Page_Selected - 5 < 0) {
                        return item > 10 ? null : (
                          <li
                            class={
                              "pagination-page ng-scope" +
                              (Page_Selected === item ? " active" : "")
                            }
                          >
                            <button
                              onClick={() => {
                                dispatch({
                                  type: UPDATE_HISTORIQUE_SELECt_PAGE,
                                  payload: item,
                                });
                                dispatch(getHistorique());
                              }}
                              class="ng-binding"
                            >
                              {item + 1}
                            </button>
                          </li>
                        );
                      } else if (
                        Page_Selected + 5 >
                        Math.ceil(totalrow / Count_Page)
                      ) {
                        return item <
                          Math.ceil(totalrow / Count_Page) - 10 ? null : (
                          <li
                            class={
                              "pagination-page ng-scope" +
                              (Page_Selected === item ? " active" : "")
                            }
                          >
                            <button
                              onClick={() => {
                                dispatch({
                                  type: UPDATE_HISTORIQUE_SELECt_PAGE,
                                  payload: item,
                                });
                                dispatch(getHistorique());
                              }}
                              class="ng-binding"
                            >
                              {item + 1}
                            </button>
                          </li>
                        );
                      } else {
                        return item >= Page_Selected - 5 &&
                          item <= Page_Selected + 5 ? (
                          <li
                            class={
                              "pagination-page ng-scope" +
                              (Page_Selected === item ? " active" : "")
                            }
                          >
                            <button
                              onClick={() => {
                                dispatch({
                                  type: UPDATE_HISTORIQUE_SELECt_PAGE,
                                  payload: item,
                                });
                                dispatch(getHistorique());
                              }}
                              class="ng-binding"
                            >
                              {item + 1}
                            </button>
                          </li>
                        ) : null;
                      }
                    })}

                    <li class="pagination-next ng-scope">
                      <a
                        onClick={() => {
                          if (
                            Page_Selected <
                            Math.ceil(totalrow / Count_Page) - 1
                          ) {
                            dispatch({
                              type: UPDATE_HISTORIQUE_SELECt_PAGE,
                              payload: Page_Selected + 1,
                            });
                            dispatch(getHistorique());
                          }
                        }}
                        class="ng-binding"
                      >
                        Next
                      </a>
                    </li>
                    <li class="pagination-last ng-scope">
                      <a
                        onClick={() => {
                          {
                            dispatch({
                              type: UPDATE_HISTORIQUE_SELECt_PAGE,
                              payload: Math.ceil(totalrow / Count_Page) - 1,
                            });
                            dispatch(getHistorique());
                          }
                        }}
                        class="ng-binding"
                      >
                        Last
                      </a>
                    </li>
                  </ul>
                </div>{" "}
              </div>
            ) : (
              <div
                ng-if="vmTransactionHistoryList.list.transaction.length===0 &amp;&amp; !vmTransactionHistoryList.dataLoadingStatus.isLoading &amp;&amp; vmTransactionHistoryList.dataLoadingStatus.isSearched"
                class="ng-scope"
              >
                <nvs-no-data-info class="ng-isolate-scope">
                  <div class="no-data-information">
                    <div class="nvs-no-data-wrapper">
                      <i class="fa icon-problem fa-5x" aria-hidden="true"></i>
                      <p
                        class="nvs-no-data-info ng-isolate-scope"
                        ng-translate=""
                        translate="_no_available_data"
                      >
                        Pas de données disponibles
                      </p>
                    </div>
                  </div>
                </nvs-no-data-info>
              </div>
            )}
          </section>
        </nvs-transaction-history-list>
      </section>
    </div>
  );
}
export default Historiquetransaction;
