import { useLocation } from "react-router";
import { Link } from "react-router-dom";

function ProfileSlidebarItem({ url, lib }) {
  const location = useLocation();
  return (
    <li
      class="nvs-profile-sidebar--content--menu--list ng-scope"
      ng-repeat="itemMenu in menu.items"
    >
      <Link
        ng-if="itemMenu.internal"
        className={
          location.pathname === url
            ? "nvs-profile-sidebar--content--menu--list-element ng-scope active"
            : "nvs-profile-sidebar--content--menu--list-element ng-scope"
        }
        to={url}
      >
        <span ng-translate="" translate="Dépôt" class="ng-isolate-scope">
          {lib}
        </span>
      </Link>
    </li>
  );
}
export default ProfileSlidebarItem;
