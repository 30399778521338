import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import "./Mainheader.css";
import { useSelector, useDispatch } from "react-redux";
import HeaderItems from "./HeaderItems";
import LoginForm from "./LoginForm";
import {
  SET_NAV_BAR_CHANGE,
  SET_NAV_USER_CHANGE,
} from "../../redux/actions/types";
function MainHeader() {
  const { t, i18n } = useTranslation();
  const [Isshowlang, setIsshowlang] = useState(false);
  const { opendnavbar, opendnavlogin, userDetails, islogin } = useSelector(
    (state) => state.general
  );
  const location = useLocation();

  console.log(i18n.language);
  const dispatch = useDispatch();
  return (
    <header className="header ">
      <div className="head-master">
        <div className="container">
          <div className="row align-items-center no-print-data">
            <div className="col-lg-4 col-12 logo-wrap">
              <a
                onClick={() => {
                  var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
                  if (opendnavlogin === false) {
                    root.setAttribute(
                      "class",
                      "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
                    );
                  } else {
                    root.removeAttribute("class");
                  }
                  dispatch({
                    type: SET_NAV_USER_CHANGE,
                  });
                }}
                className="btn-user-trigger btn btn-white-outline btn-sm hidden-lg-up"
              >
                <i className="fa fa-user"></i>
                {islogin === true ? (
                  <span className="js-username">{userDetails.username}</span>
                ) : (
                  <span className="js-username">{t("account.part56")}</span>
                )}
              </a>
            </div>

            <div className="col-md-8 col-12 d-flex justify-content-lg-end justify-content-end hidden-md-down">
              <LoginForm></LoginForm>
            </div>
          </div>
        </div>
      </div>

      <div className="head-nav-wrap hidden-md-down">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex">
              <ul className="nav navigation-main align-items-center">
                
                 
                {userDetails.type_User < 5 ? (
                  <HeaderItems
                    path="/account/transaction"
                    lib={t("account.part13")}
                  />
                ) : (
                 null 
                )}
                {userDetails.type_User < 5 ? (
                  <HeaderItems
                    path="/account/Historique"
                    lib={t("account.part2")}
                  />
                ) : (
                  null
                )}
             
              
                {/* <HeaderItems path="/virtual" lib={t("Headers.part5")} />*/}
              </ul>

              <div className="navigation-right">
                <ul className="nav navigation-main align-items-center">
                  {userDetails.type_User === 5 ? (
                    <HeaderItems path="/mybets" lib={t("Headers.part7")} />
                  ) : null}
                  <a
                    onClick={() => setIsshowlang(!Isshowlang)}
                    className="language-list-item"
                    id="lang-trigger"
                    title="English"
                    data-lang="en"
                  >
                    {i18n.language === "en" ? (
                      <span className="flag flag-gb" alt="English"></span>
                    ) : i18n.language === "ar" ? (
                      <span className="flag flag-tn" alt="Arab"></span>
                    ) : i18n.language === "it" ? (
                      <span className="flag flag-it" alt="Italy"></span>
                    ) : (
                      <span className="flag flag-fr" alt="Francais"></span>
                    )}{" "}
                    <i
                      className="language-arrow fa fa-chevron-down"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="lang-drop"
                    style={Isshowlang === false ? { display: "none" } : {}}
                  >
                    {/*<a
                      title="عربى"
                      data-lang="ar"
                      className="language-list-item"
                    >
                      <span className="flag flag-dz" alt="عربى"></span>
                   </a>*/}
                    {i18n.language !== "fr" ? (
                      <a
                        onClick={() => {
                          i18n.changeLanguage("fr");
                          setIsshowlang(!Isshowlang);
                        }}
                        title="French"
                        data-lang="fr"
                        className="language-list-item"
                      >
                        <span className="flag flag-fr" alt="French"></span>
                      </a>
                    ) : null}{" "}
                    {i18n.language !== "en" ? (
                      <a
                        onClick={() => {
                          i18n.changeLanguage("en");
                          setIsshowlang(!Isshowlang);
                        }}
                        className="language-list-item"
                        id="lang-trigger"
                        title="English"
                        data-lang="en"
                      >
                        <span className="flag flag-gb" alt="English"></span>
                      </a>
                    ) : null}{" "}
                    {i18n.language !== "ar" ? (
                      <a
                        onClick={() => {
                          i18n.changeLanguage("ar");
                          setIsshowlang(!Isshowlang);
                        }}
                        title="Arab"
                        data-lang="ar"
                        className="language-list-item"
                      >
                        <span className="flag flag-tn" alt="Arab"></span>
                      </a>
                    ) : null}
                    {i18n.language !== "it" ? (
                      <a
                        onClick={() => {
                          i18n.changeLanguage("it");
                          setIsshowlang(!Isshowlang);
                        }}
                        title="italy"
                        data-lang="it"
                        className="language-list-item"
                      >
                        <span className="flag flag-it" alt="italy"></span>
                      </a>
                    ) : null}{" "}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default MainHeader;
