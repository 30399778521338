import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetListUsers } from "../../redux/actions/Listusers";
import {
  SET_LIST_USER_ID_USER,
  SET_LIST_USER_USERNAME,
} from "../../redux/actions/types";

import "./Historiqueparis.css";
import "./Historiquetransaction.css";
import "./Listusers.css";
import ListUsersitem from "./ListUsersitem";
function Listusers() {
  const { listuser, username } = useSelector((state) => state.Listusers);
  const { userDetails, width_form } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetListUsers());
  }, []);
  const { t } = useTranslation();
  return (
    <div ui-view="content" className="ng-scope">
      <section id="accountListContainer" className="ng-scope">
        <div>
          <h4 translate="transaction_history" className="ng-isolate-scope">
            {t("account.part8")}
          </h4>
          <nvs-transaction-history-filter className="ng-isolate-scope">
            <section id="transactionHistoryFilterContainer">
              <div
                name="transactionHistoryFilterForm"
                novalidate=""
                className="ng-pristine ng-valid ng-valid-datetime"
              >
                {width_form > 767 ? (
                  <div class="input-group-container">
                    <div class="input-group-item">
                      <label
                        for=""
                        translate="user_id"
                        class="ng-isolate-scope"
                      >
                        {t("account.part30")}
                      </label>
                      <input
                        onChange={(e) =>
                          dispatch({
                            type: SET_LIST_USER_ID_USER,
                            payload: e.target.value,
                          })
                        }
                        class="form-input ng-pristine ng-valid ng-isolate-scope ng-empty ng-touched"
                        type="text"
                        placeholder={t("account.part30")}
                        aria-invalid="false"
                      />
                    </div>
                    <div class="input-group-item">
                      <label for="" class="ng-isolate-scope">
                        {t("account.part19")}
                      </label>
                      <input
                        onChange={(e) =>
                          dispatch({
                            type: SET_LIST_USER_USERNAME,
                            payload: e.target.value,
                          })
                        }
                        class="form-input ng-pristine ng-untouched ng-valid ng-isolate-scope ng-empty"
                        type="text"
                        ng-placeholder="username"
                        ng-model="vmAccountListFilter.data.username"
                        placeholder={t("account.part19")}
                        aria-invalid="false"
                      />
                    </div>
                    <div class="input-group-item inline">
                      <button
                        onClick={(e) => dispatch(GetListUsers())}
                        class="button button-primary"
                        ng-click="vmAccountListFilter.search()"
                      >
                        <i class="fa fa-search"></i>{" "}
                        <span translate="search" class="ng-isolate-scope">
                          {t("account.part33")}
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div class="input-group-container">
                      <div class="input-group-item">
                        <label
                          for=""
                          translate="user_id"
                          class="ng-isolate-scope"
                        >
                          {t("account.part30")}
                        </label>
                        <input
                          onChange={(e) =>
                            dispatch({
                              type: SET_LIST_USER_ID_USER,
                              payload: e.target.value,
                            })
                          }
                          class="form-input ng-pristine ng-valid ng-isolate-scope ng-empty ng-touched"
                          type="text"
                          ng-placeholder="user_id"
                          ng-model="vmAccountListFilter.data.userId"
                          placeholder={t("account.part30")}
                          aria-invalid="false"
                        />
                      </div>
                    </div>
                    <div class="input-group-container">
                      <div class="input-group-item">
                        <label
                          for=""
                          translate="username"
                          class="ng-isolate-scope"
                        >
                          {t("account.part19")}{" "}
                        </label>
                        <input
                          onChange={(e) =>
                            dispatch({
                              type: SET_LIST_USER_USERNAME,
                              payload: e.target.value,
                            })
                          }
                          class="form-input ng-pristine ng-untouched ng-valid ng-isolate-scope ng-empty"
                          type="text"
                          ng-placeholder="username"
                          ng-model="vmAccountListFilter.data.username"
                          placeholder={t("account.part19")}
                          aria-invalid="false"
                        />
                      </div>
                    </div>
                    <div class="input-group-container">
                      <div class="input-group-item inline">
                        <button
                          onClick={(e) => dispatch(GetListUsers())}
                          class="button button-primary"
                          ng-click="vmAccountListFilter.search()"
                        >
                          <i class="fa fa-search"></i>{" "}
                          <span translate="search" class="ng-isolate-scope">
                            {t("account.part33")}
                          </span>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </section>
          </nvs-transaction-history-filter>
        </div>

        <div>
          <nvs-account-list
            class="ng-isolate-scope"
            style={{ minHeight: "150px" }}
          >
            <section id="accountListContainer" class="ng-scope">
              <div class="nvs-AccountListTable">
                <div class="nvs-AccountListTable-header-labels sub-level">
                  <span translate="sub_users" class="ng-isolate-scope">
                    {t("account.part34")}
                  </span>
                </div>
                <div class="nvs-AccountListTable-header-labels symbol-labels">
                  <span translate="level" class="ng-isolate-scope">
                    {t("account.part35")}
                  </span>
                </div>
                <div class="nvs-AccountListTable-header-labels symbol-labels">
                  <span translate="user_type" class="ng-isolate-scope">
                    {t("account.part36")}
                  </span>
                </div>
                <div class="nvs-AccountListTable-header-labels">
                  <span translate="username" class="ng-isolate-scope">
                    {t("account.part19")}
                  </span>
                </div>

                <div class="nvs-AccountListTable-header-labels">
                  <span translate="balance" class="ng-isolate-scope">
                    {t("account.part29")}
                  </span>
                </div>
                <div class="nvs-AccountListTable-header-labels">
                  <span translate="trust" class="ng-isolate-scope">
                    {t("account.part31")}
                  </span>
                </div>
                <div class="nvs-AccountListTable-header-labels">
                  <span translate="status" class="ng-isolate-scope">
                    {t("account.part37")}
                  </span>
                </div>
                <div
                  class="nvs-AccountListTable-header-labels"
                  style={{ width: "16%" }}
                >
                  <span translate="transfer" class="ng-isolate-scope">
                    {t("account.part38")}
                  </span>
                </div>
                <div class="nvs-AccountListTable-header-labels actions">
                  <span translate="actions" class="ng-isolate-scope">
                    {t("account.part40")}
                  </span>
                </div>
                <nvs-account-tree-table
                  owner-id="vmAccountList.user.id"
                  reports="vmAccountList.reportList"
                  template="list"
                  class="nvs-AccountListTable ng-isolate-scope"
                >
                  <div>
                    {width_form > 767
                      ? listuser
                          .filter(
                            (i) =>
                              i.username
                                .toUpperCase()
                                .indexOf(username.toUpperCase()) >= 0
                          )
                          .map((item) => {
                            return (
                              <ListUsersitem
                                key={item.id}
                                user={item}
                              ></ListUsersitem>
                            );
                          })
                      : listuser
                          .filter(
                            (i) =>
                              i.username
                                .toUpperCase()
                                .indexOf(username.toUpperCase()) >= 0
                          )
                          .map((item) => {
                            return (
                              <ListUsersitem
                                key={item.id}
                                user={item}
                              ></ListUsersitem>
                            );
                          })}
                  </div>
                </nvs-account-tree-table>
              </div>
            </section>
          </nvs-account-list>
        </div>
      </section>
    </div>
  );
}
export default Listusers;
