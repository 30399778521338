import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import "./Historiqueparis.css";
function Historiqueparis() {
  const { islogin, geolocation } = useSelector((state) => state.general);
  const divRef = React.useRef();
  const { idbet } = useParams();
  useEffect(() => {
    function initpage() {
      const skin = geolocation.country_code === "TN" ? "maxbet" : "Eljam3ia";
      console.log(idbet);

      const initParams = {
        walletcode: "291021",
        lang: "FR",
        skinid: skin,
        page: "reports",
        token: JSON.parse(localStorage.getItem("token")),
        fixedbottom: 56,
        betid: idbet,
      };
      try {
        divRef.current.removeChild(divRef.current.children[0]);
      } catch {}
      if (window.AltenarSportsbook) {
        var ASbd = new window.AltenarSportsbook(
          "#altenarsportsbook",
          initParams
        );
        //setAsb(ASbd);
      }
    }

    initpage();
  }, [islogin, geolocation]);
  return (
    <div ui-view="content" className="ng-scope">
      <div id="altenarsportsbook"></div>
    </div>
  );
}
export default Historiqueparis;
