import { useDispatch, useSelector } from "react-redux";
import {
  UPTATE_EMAIL_USER,
  UPTATE_LAST_USER,
  UPTATE_NAME_USER,
} from "../../redux/actions/types";
import { InsertNewAccount } from "../../redux/actions/NewAccount";
import "./NewAccount.css";
import { useTranslation } from "react-i18next";
import { changedetails } from "../../redux/actions/general";
function Myaccount() {
  const { InsaveUpdate, userDetails } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  return (
    <div ui-view="content" className="ng-scope">
      <section id="newUserContainer" className="ng-scope">
        <h3 translate="new_user" className="ng-isolate-scope">
          {t("account.part4")}
        </h3>
        <nvs-new-user-form className="ng-isolate-scope">
          <section id="newUserFormContainer">
            <div
              novalidate=""
              className="ng-pristine ng-invalid ng-invalid-required ng-valid-pattern ng-valid-minlength ng-valid-email"
              aria-hidden="false"
            >
              <div className="input-group-container">
                <div className="input-group-item">
                  <div>
                    <div>
                      <label className="ng-isolate-scope">
                        {t("account.part17")}
                      </label>{" "}
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userDetails.id}
                        disabled="disabled"
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength"
                      />
                    </div>
                  </div>
                </div>
                <div className="input-group-item">
                  <div>
                    <div>
                      <label
                        ng-translate=""
                        translate="username"
                        className="ng-isolate-scope"
                      >
                        {t("account.part19")}
                      </label>{" "}
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userDetails.username}
                        disabled="disabled"
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-group-container">
                <div className="input-group-item">
                  <div>
                    <div>
                      <label translate="password" className="ng-isolate-scope">
                        {t("account.part20")}
                      </label>{" "}
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userDetails.Email}
                        onChange={(el) =>
                          dispatch({
                            type: UPTATE_EMAIL_USER,
                            payload: el.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength"
                      />
                    </div>
                  </div>
                </div>
                <div className="input-group-item">
                  <div>
                    <div>
                      <label
                        ng-translate=""
                        translate="username"
                        className="ng-isolate-scope"
                      >
                        {t("account.part21")}
                      </label>{" "}
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userDetails.Name}
                        onChange={(el) =>
                          dispatch({
                            type: UPTATE_NAME_USER,
                            payload: el.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                      />
                    </div>
                  </div>
                </div>
                <div className="input-group-item">
                  <div>
                    <div>
                      <label
                        ng-translate=""
                        translate="username"
                        className="ng-isolate-scope"
                      >
                        {t("account.part23")}
                      </label>{" "}
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userDetails.LastName}
                        onChange={(el) =>
                          dispatch({
                            type: UPTATE_LAST_USER,
                            payload: el.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="input-group-container">
                <div className="input-group-item">
                  <button
                    onClick={() => dispatch(changedetails())}
                    type="submit"
                    disabled={InsaveUpdate === true ? "disabled" : ""}
                    class={
                      InsaveUpdate === true
                        ? "button button-primary w-100 ng-isolate-scope btn-loading"
                        : "button button-primary w-100 ng-isolate-scope"
                    }
                    translate="Save"
                  >
                    {t("account.part24")}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </nvs-new-user-form>
      </section>
    </div>
  );
}
export default Myaccount;
