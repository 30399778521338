import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getUSerDetails } from "../../redux/actions/general";
import {
  GET_USER_DETAILS,
  SET_NAV_USER_CHANGE,
} from "../../redux/actions/types";
import md5 from "react-native-md5";

function MobileUsernav() {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, seterror] = useState("");
  const [isloadinglogin, setisloadinglogin] = useState(false);

  const isopened = useSelector((state) => state.general.opendnavlogin);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { opendnavlogin, islogin, userDetails } = useSelector(
    (state) => state.general
  );
  const wrapperRef = useRef(null);

  const closenav = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      wrapperRef.current.classList.contains("mm-opened")
    ) {
      var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)

      root.removeAttribute("class");

      dispatch({
        type: SET_NAV_USER_CHANGE,
        payload: false,
      });
    }
  };
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    // Bind the event listener
    document.addEventListener("mousedown", (e) => closenav(e));
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", (e) => closenav(e));
    };
  }, []);
  const loginUser = () => {
    const key = process.env.REACT_APP_MD_CR_A;
    const crypt = md5.hex_md5(username + password + key);
    fetch(process.env.REACT_APP_URL_API + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
        sig: crypt,
      }),
    })
      .then((data) => data.json())
      .then((token) => {
        if (token.status === 400) {
          //error message
          seterror("ErrorLogin");
        } else {
          seterror("");
          setisloadinglogin(true);
          localStorage.setItem("token", JSON.stringify(token.token));
          dispatch(getUSerDetails());
          var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
          if (opendnavlogin === false) {
            root.setAttribute(
              "class",
              "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
            );
          } else {
            root.removeAttribute("class");
          }
          dispatch({
            type: SET_NAV_USER_CHANGE,
          });
        }
      });
  };
  return (
    <nav
      id="nav-mobile-user"
      ref={wrapperRef}
      className={
        isopened === true
          ? "mm-menu mm-effect-listitems-slide mm-shadow-page mm-offcanvas mm-right mm-next mm-hasnavbar-top-1 mm-current mm-opened"
          : "mm-menu mm-effect-listitems-slide mm-shadow-page mm-offcanvas mm-right mm-next mm-hasnavbar-top-1"
      }
    >
      <div className="mm-navbar mm-navbar-top mm-navbar-top-1 mm-navbar-size-1 mm-hasbtns">
        <a className="mm-prev mm-btn mm-hidden"></a>
        <a
          className="mm-close mm-btn"
          onClick={() => {
            var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
            if (opendnavlogin === false) {
              root.setAttribute(
                "class",
                "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
              );
            } else {
              root.removeAttribute("class");
            }
            dispatch({
              type: SET_NAV_USER_CHANGE,
            });
          }}
        ></a>
      </div>
      <div className="mm-panels">
        <div className="mm-panel mm-opened mm-current" id="mm-3">
          <div className="mm-navbar mm-hidden">
            <a className="mm-title">Menu</a>
          </div>
          {islogin === false ? (
            <ul className="mm-listview">
              <li>
                <span className="nav-mobile-user-login">
                  <div className="form-inline form-login flex-lg-nowrap">
                    <div className="input-group on-dark mr-lg-2 mb-lg-0 mb-2">
                      <input
                        type="text"
                        className="form-control login-username"
                        placeholder={t("account.part19")}
                        required=""
                        onChange={(text) => setUsername(text.target.value)}
                        value={username}
                      />
                    </div>

                    <div className="input-group on-dark mr-lg-2 mb-lg-0 mb-2">
                      <input
                        value={password}
                        type="password"
                        className="form-control login-password"
                        placeholder={t("account.part22")}
                        required=""
                        onChange={(text) => setPassword(text.target.value)}
                      />
                    </div>

                    <button
                      onClick={() => loginUser()}
                      className={
                        isloadinglogin === false
                          ? "btn btn-secondary-fancy btn-register"
                          : "btn btn-primary mr-sm-2 mb-sm-0 btn-login-form btn-loading"
                      }
                    >
                      {t("account.part56")}
                    </button>

                    <span
                      className="login-errors"
                      style={
                        error === ""
                          ? { display: "none" }
                          : { display: "block", color: "red" }
                      }
                    >
                      {" "}
                      {t("error_message.Error_4")}
                    </span>
                  </div>
                </span>
              </li>
            </ul>
          ) : (
            <ul class="mm-listview">
              <li>
                <span class="nav-mobile-user-info">
                  <span class="clear">
                    <b class="js-username">{userDetails.username}</b>
                    <span class="btn-refresh">
                      <i class="fa fa-refresh"></i>
                    </span>
                  </span>
                  <span class="clear">ID : {userDetails.id}</span>
                  <span class="clear">
                    <b>Cash:</b>
                    <span class="js-balance-cash">
                      TND {userDetails.Balance.toFixed(3)}
                    </span>
                  </span>

                  <span class="clear">
                    <b>Bonus Sports:</b>
                    <span class="js-sports-bonus">TND 0.000</span>
                  </span>

                  <span class="clear">
                    <b>Bonus Casino:</b>
                    <span class="js-casino-bonus">TND 0.000</span>
                  </span>
                </span>
              </li>
              <li>
                <Link
                  to="/account/transaction"
                  onClick={() => {
                    var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
                    if (opendnavlogin === false) {
                      root.setAttribute(
                        "class",
                        "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
                      );
                    } else {
                      root.removeAttribute("class");
                    }
                    dispatch({
                      type: SET_NAV_USER_CHANGE,
                    });
                  }}
                >
                  <i class="fa fa-user-circle-o" title="Mon compte"></i>
                  {t("account.part13")}
                </Link>{" "}
              </li>
              <li>
                <Link
                  to="/account"
                  onClick={() => {
                    var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
                    if (opendnavlogin === false) {
                      root.setAttribute(
                        "class",
                        "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
                      );
                    } else {
                      root.removeAttribute("class");
                    }
                    dispatch({
                      type: SET_NAV_USER_CHANGE,
                    });
                  }}
                >
                  <i class="fa fa-user-circle-o" title="Mon compte"></i>
                  {t("account.part4")}
                </Link>{" "}
              </li>
              {userDetails.type_User < 5 ? (
                <>
                  {" "}
                  <li>
                    <Link
                      to="/account/newuser"
                      onClick={() => {
                        var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
                        if (opendnavlogin === false) {
                          root.setAttribute(
                            "class",
                            "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
                          );
                        } else {
                          root.removeAttribute("class");
                        }
                        dispatch({
                          type: SET_NAV_USER_CHANGE,
                        });
                      }}
                    >
                      <i class="fa fa-user-circle-o" title="Mon compte"></i>
                      {t("account.part7")}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to="/account/accounts"
                      onClick={() => {
                        var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
                        if (opendnavlogin === false) {
                          root.setAttribute(
                            "class",
                            "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
                          );
                        } else {
                          root.removeAttribute("class");
                        }
                        dispatch({
                          type: SET_NAV_USER_CHANGE,
                        });
                      }}
                    >
                      <i class="fa fa-user-circle-o" title="Mon compte"></i>
                      {t("account.part8")}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to="/account/reportrevenus"
                      onClick={() => {
                        var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
                        if (opendnavlogin === false) {
                          root.setAttribute(
                            "class",
                            "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
                          );
                        } else {
                          root.removeAttribute("class");
                        }
                        dispatch({
                          type: SET_NAV_USER_CHANGE,
                        });
                      }}
                    >
                      <i class="fa fa-user-circle-o" title="Mon compte"></i>
                      {t("account.part10")}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to="/account/Historique"
                      onClick={() => {
                        var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
                        if (opendnavlogin === false) {
                          root.setAttribute(
                            "class",
                            "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
                          );
                        } else {
                          root.removeAttribute("class");
                        }
                        dispatch({
                          type: SET_NAV_USER_CHANGE,
                        });
                      }}
                    >
                      <i class="fa fa-user-circle-o" title="Mon compte"></i>

                      {t("account.part2")}
                    </Link>{" "}
                  </li>
                </>
              ) : null}
              <li>
                <Link
                  to="/account/password"
                  onClick={() => {
                    var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
                    if (opendnavlogin === false) {
                      root.setAttribute(
                        "class",
                        "mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
                      );
                    } else {
                      root.removeAttribute("class");
                    }
                    dispatch({
                      type: SET_NAV_USER_CHANGE,
                    });
                  }}
                >
                  <i class="fa fa-user-circle-o" title="Mon compte"></i>
                  {t("account.part5")}
                </Link>{" "}
              </li>

              <li>
                <a
                  onClick={() => {
                    setisloadinglogin(false);
                    dispatch({
                      type: GET_USER_DETAILS,
                      payload: { status: 400 },
                    });
                  }}
                  class="btn-logout"
                  href="#"
                  title="Déconnexion"
                >
                  <i class="fa fa-power-off"></i>
                  Déconnexion{" "}
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}

export default MobileUsernav;
