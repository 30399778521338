import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function AccountHome() {
  const { userDetails } = useSelector((state) => state.general);
  const { t } = useTranslation();
  return (
    <div class="container-fluid sidebar-container">
      <div class="row">
        <div class="col-lg-3">
          <div class="sidebar-content">
            <nav id="sidebar-menu" class="d-flex flex-column sidebar-menu">
              <div>
                <ul>
                  <li id="userinfo">
                    <i
                      class="fa fa-user-circle-o mr-2 text-dark"
                      style={{ fontSize: "48px", color: "black" }}
                    ></i>{" "}
                    <Link to="" class="js-username">
                      {userDetails.username}
                    </Link>{" "}
                    <a class="menu-role">
                      {t("user_type.part" + userDetails.type_User)}
                    </a>
                  </li>{" "}
                  <li id="296" class="list-item ">
                    <Link to="/account/transaction">
                      <i class="fa fa-exchange mr-auto"></i>
                      {t("account.part13")}
                    </Link>
                  </li>{" "}
                  <li id="296" class="list-item ">
                    <Link to="/account/newuser">
                      <i class="fa fa-user mr-auto"></i>
                      {t("account.part7")}
                    </Link>
                  </li>{" "}
                  <li id="296" class="list-item ">
                    <Link to="/account/accounts">
                      <i class="fa fa-money mr-auto"></i>
                      {t("account.part8")}
                    </Link>
                  </li>{" "}
                  <li id="296" class="list-item ">
                    <Link to="/account/Transactionfinance">
                      <i class="fa fa-tachometer mr-auto"></i>
                      {t("Transaction financière")}
                    </Link>
                  </li>{" "}
                  <li id="296" class="list-item ">
                    <Link to="/account/password">
                      <i class="fa fa-tachometer mr-auto"></i>
                      {t("account.part5")}
                    </Link>
                  </li>{" "}
                  <li id="296" class="list-item ">
                    <Link to="/account">
                      <i class="fa fa-tachometer mr-auto"></i>
                      {t("account.part4")}
                    </Link>
                  </li>{" "}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AccountHome;
