import axios from "axios";
import { useState } from "react";
import ReactDOM from "react-dom";
import md5 from "react-native-md5";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

function Confirmepassword(props) {
  const [password, setpassword] = useState("");
  const dispatch = useDispatch();
  const { userid, username } = props;
  async function confirmchangePassword() {
    const key = process.env.REACT_APP_MD_CR_A;
    const crypt = md5.hex_md5(
      JSON.parse(localStorage.getItem("token")) + userid + password + key
    );
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_URL_API}` + "updatepassworduser",
        {
          token: JSON.parse(localStorage.getItem("token")),
          Password: password,
          userid: userid,
          sig: crypt,
        }
      );
      if (result.data.status === 200) {
        toastr.success("success");

        ReactDOM.unmountComponentAtNode(
          document.getElementById("confirmtransaction")
        );
      } else {
        toastr.error("error Change Password");

        ReactDOM.unmountComponentAtNode(
          document.getElementById("confirmtransaction")
        );
      }
    } catch (error) {
      toastr.error("error Change Passsword");
    }
  }
  const cancelchangepassword = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("confirmtransaction")
    );
  };
  return (
    <div
      modal-render="true"
      tabindex="-1"
      role="dialog"
      class="modal fade ng-isolate-scope in"
      uib-modal-animation-class="fade"
      modal-in-class="in"
      ng-style="{'z-index': 1050 + index*10, display: 'block'}"
      uib-modal-window="modal-window"
      size="lg"
      index="0"
      animate="animate"
      modal-animation="true"
      style={{ zIndex: "1050", display: "block" }}
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content" uib-modal-transclude="">
          <div
            nvs-edit-password-form=""
            close-popup="closePopup(data)"
            user="user"
            class="ng-scope ng-isolate-scope"
          >
            <div id="nvs-AccountList-editPasswordContainer" class="modal-body">
              <h3
                class="nvs-AccountList-editPassword-title ng-isolate-scope"
                ng-translate=""
                translate="change_password"
              >
                Changer le mot de passe
              </h3>
              <div class="nvs-AccountList-editPassword-body">
                <label
                  class="nvs-AccountList-editPassword-body-label ng-isolate-scope"
                  for="currentPassword"
                  ng-translate=""
                  translate="change_password"
                >
                  Changer le mot de passe
                </label>
                <input
                  type="password"
                  name="password1"
                  class="nvs-AccountList-editPassword-body-input ng-pristine ng-valid ng-empty ng-touched"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  autocomplete="off"
                  aria-invalid="false"
                />
              </div>
              <div class="nvs-AccountList-editPassword-body">
                <div class="nvs-AccountList-editPassword-body-checkbox"></div>
                <div class="nvs-AccountList-editPassword-body-buttons">
                  <button
                    onClick={confirmchangePassword}
                    class="nvs-AccountList-editPassword-body-save-btn"
                  >
                    <span class="save-btn-icon"></span>{" "}
                    <span
                      ng-translate=""
                      translate="save"
                      class="ng-isolate-scope"
                    >
                      Sauvegarder
                    </span>
                  </button>{" "}
                  <button
                    onClick={cancelchangepassword}
                    class="nvs-AccountList-editPassword-body-close-btn"
                    ng-click="vmEditPasswordForm.close()"
                  >
                    <span class="close-btn-icon"></span>{" "}
                    <span
                      ng-translate=""
                      translate="close"
                      class="ng-isolate-scope"
                    >
                      Fermer
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Confirmepassword;
