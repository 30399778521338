import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import AccountHome from "./AccountHome";
import "./AccountMainV2.css";
import ChangePassword from "./ChangePassword";
import FinanaceTransaction from "./FinanaceTransaction";
import Historiqueparis from "./Historiqueparis";
import Historiquetransaction from "./Historiquetransaction";
import Listusers from "./Listusers";
import Myaccount from "./Myaccount";
import NewAccount from "./NewAccount";
import ProfileSidebar from "./ProfileSidebar";
import Revenus from "./Revenus";
function AccountMainV2() {
  const { userDetails } = useSelector((state) => state.general);

  return (
    <section class="container-fluid section-betHistory">
      <div ui-view="" class="row ng-scope">
        <section id="profileContainer" class="ng-scope">
          <ProfileSidebar />
          <div class="profile-content">
            <Switch>
              <Route
                exact
                path={"/account"}
                render={(props) => <Myaccount {...props} />}
              />
              <Route
                exact
                path={"/account/transaction"}
                render={(props) => <Historiquetransaction {...props} />}
              />
              {userDetails.type_User < 5 ? (
                <Route
                  exact
                  path={"/account/newuser"}
                  render={(props) => <NewAccount {...props} />}
                />
              ) : null}
              {userDetails.type_User < 5 ? (
                <Route
                  exact
                  path={"/account/Transactionfinance"}
                  render={(props) => <FinanaceTransaction {...props} />}
                />
              ) : null}
              <Route
                exact
                path={"/account/password"}
                render={(props) => <ChangePassword {...props} />}
              />
              {userDetails.type_User < 5 ? (
                <Route
                  exact
                  path="/account/accounts"
                  render={(props) => <Listusers {...props} />}
                />
              ) : null}
              {userDetails.type_User < 5 ? (
                <Route
                  path="/account/Historique/:idbet?"
                  render={(props) => <Historiqueparis {...props} />}
                />
              ) : null}{" "}
              {userDetails.type_User < 5 ? (
                <Route
                  path="/account/reportrevenus"
                  render={(props) => <Revenus {...props} />}
                />
              ) : null}
              {userDetails.type_User < 5 ? (
                <Route
                  path="/"
                  render={(props) => <AccountHome {...props} />}
                />
              ) : null}
            </Switch>
          </div>
        </section>
      </div>
    </section>
  );
}
export default AccountMainV2;
