import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import NavbarItem from "./NavbarItem";
import {
  SET_NAV_BAR_CHANGE,
  SET_NAV_USER_CHANGE,
} from "../../redux/actions/types";

function MobileNavbar() {
  const wrapperRef = useRef(null);

  const isopened = useSelector((state) => state.general.opendnavbar);
  const dispatch = useDispatch();
  const { opendnavbar, userDetails } = useSelector((state) => state.general);

  const { t, i18n } = useTranslation();
  const closenav = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      wrapperRef.current.classList.contains("mm-opened")
    ) {
      var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)

      root.removeAttribute("class");

      dispatch({
        type: SET_NAV_BAR_CHANGE,
        payload: false,
      });
    }
  };
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    // Bind the event listener
    document.addEventListener("mousedown", (e) => closenav(e));
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", (e) => closenav(e));
    };
  }, []);
  return (
    <nav
      ref={wrapperRef}
      id="nav-mobile"
      className={
        isopened === true
          ? "mm-menu mm-effect-listitems-slide mm-shadow-page mm-offcanvas mm-next mm-hasnavbar-top-1 mm-current mm-opened"
          : "mm-menu mm-effect-listitems-slide mm-shadow-page mm-offcanvas mm-next mm-hasnavbar-top-1"
      }
    >
      <div className="mm-navbar mm-navbar-top mm-navbar-top-1 mm-navbar-size-1 mm-hasbtns">
        <a className="mm-prev mm-btn mm-hidden"></a>
        <a
          className="mm-close mm-btn"
          onClick={() => {
            var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
            if (opendnavbar === false) {
              root.setAttribute(
                "class",
                "mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening"
              );
            } else {
              root.removeAttribute("class");
            }
            dispatch({
              type: SET_NAV_BAR_CHANGE,
            });
          }}
        ></a>
      </div>
      <div className="mm-panels">
        <div className="mm-panel mm-opened mm-current" id="mm-1">
          <div className="mm-navbar mm-hidden">
            <a className="mm-title">Menu</a>
          </div>
          <ul className="mm-listview">
            <li>
              {/*<span className="nav-mobile-logo">
                <img
                  src="https://www.starsbet365.com/remote-assets/logo-mobile.png?1603106051"
                  alt="Starsbet365"
                  className="logo-mobile"
                />
        </span>*/}
            </li>
            <NavbarItem url="/" lib={t("Headers.part1")} />
            <NavbarItem url="/live" lib={t("Headers.part2")} />
            <NavbarItem url="/casino" lib={t("Headers.part3")} />
            <NavbarItem url="/Livecasino" lib={t("Headers.part4")} />
            {userDetails.type_User !== 5 ? null : (
              <NavbarItem url="/minigame" lib={"Mini Game"} />
            )}
            {userDetails.type_User !== 5 ? null : (
              <NavbarItem url="/TVbet" lib={"TVBET"} />
            )}
            <li>
              <a className=" mm-fullsubopen"></a>
              <span
                className="Spacer"
                onClick={() => i18n.changeLanguage("en")}
              >
                <i className="fa fa-language"></i>
                English{" "}
              </span>{" "}
              <span
                className="Spacer"
                onClick={() => i18n.changeLanguage("fr")}
              >
                <i className="fa fa-language"></i>
                Francais{" "}
              </span>
              <span
                className="Spacer"
                onClick={() => i18n.changeLanguage("ar")}
              >
                <i className="fa fa-language"></i>
                Arab{" "}
              </span>
              <span
                className="Spacer"
                onClick={() => i18n.changeLanguage("it")}
              >
                <i className="fa fa-language"></i>
                Italian{" "}
              </span>
            </li>
            <li>
              <a className=" mm-fullsubopen"></a>
              <span
                className="Spacer"
                onClick={() => i18n.changeLanguage("it")}
              >
                <i className="fa fa-language"></i>
                Italian{" "}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default MobileNavbar;
