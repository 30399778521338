import axios from "axios";
import ReactDOM from "react-dom";
import md5 from "react-native-md5";
import { useDispatch } from "react-redux";
import { UPDATE_LIST_USER_BYID } from "../../redux/actions/types";
import { toastr } from "react-redux-toastr";
import React from "react";

function ConfirmeSendMoney(props) {
  const { amount, userid, typesend, username } = props;
  const dispatch = useDispatch();
  const refyes = React.useRef();
  async function confirmsend() {
    refyes.current.setAttribute("disabled", "disabled");
    const username1 = userid;
    const ammount = parseFloat(amount);
    const typeselect = typesend;
    const key = process.env.REACT_APP_MD_CR_A;
    const crypt = md5.hex_md5(
      JSON.parse(localStorage.getItem("token")) +
        username1 +
        ammount +
        typeselect +
        key
    );
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_URL_API}` + "sendmoney",
        {
          token: JSON.parse(localStorage.getItem("token")),
          userid: username1,
          amount: ammount,
          type: typeselect,
          sig: crypt,
        }
      );
      if (result.data.status === 200) {
        if (props.Update_Sub_USer) {
          props.Update_Sub_USer(result.data.data);
        } else {
          dispatch({ type: UPDATE_LIST_USER_BYID, payload: result.data.data });
        }
        toastr.success("success", "");
        ReactDOM.unmountComponentAtNode(
          document.getElementById("confirmtransaction")
        );
      } else {
        toastr.error("error send money");
      }
    } catch (error) {
      toastr.error("error send money");
    }
  }
  const cancelsend = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("confirmtransaction")
    );
  };
  return (
    <div
      modal-render="true"
      tabindex="-1"
      role="dialog"
      class="modal fade ng-isolate-scope in"
      uib-modal-animation-class="fade"
      modal-in-class="in"
      uib-modal-window="modal-window"
      size="sm"
      index="0"
      animate="animate"
      modal-animation="true"
      style={{ zIndex: "1050", display: "block" }}
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content" uib-modal-transclude="">
          <div
            nvs-amount-transfer-confirm-form=""
            close-popup="closePopup(data)"
            user-id="userId"
            amount="amount"
            user-name="userName"
            type="type"
            class="ng-scope ng-isolate-scope"
          >
            <div id="amount-transfer-confirm">
              <div class="amount-transfer-confirm-header">
                <p
                  ng-translate=""
                  translate="confirm_deposit_transfer"
                  class="ng-isolate-scope"
                >
                  Transaction confirmée
                </p>
                <span class="ng-binding">{username}</span>
              </div>
              <h2 currency-format="" currency="1" class="ng-isolate-scope">
                TND {parseFloat(amount).toFixed(3)}
              </h2>
              <div class="confirm-container">
                <button
                  ref={refyes}
                  onClick={(e) => confirmsend()}
                  class="confirm-yes ng-isolate-scope"
                  ng-click="vmAmountTransferConfirmForm.transfer()"
                  ng-disabled="vmAmountTransferConfirmForm.popupButtonDisabledStatus.status"
                  ng-translate=""
                  translate="yes"
                >
                  Oui
                </button>
                <button
                  onClick={() => cancelsend()}
                  class="confirm-no ng-isolate-scope"
                  ng-click="vmAmountTransferConfirmForm.close()"
                  ng-translate=""
                  translate="no"
                >
                  Non
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ConfirmeSendMoney;
