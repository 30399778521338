import React, { Suspense, useEffect, useRef } from "react";
import "./App.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import MainHeader from "./Component/header/MainHeader";
import Sport from "./Component/Sports/Sport";
import SportLive from "./Component/Sports/SportLive";
import { Switch, Route } from "react-router-dom";
import Virtual from "./Component/Sports/Virtual";
import Mybets from "./Component/Sports/Mybets";
import CasinoHome from "./Component/Casino/CasinoHome";
import Loading from "./Component/Loading/Loading";
import MobileNavbar from "./Component/mobile/MobileNavbar";
import MobileUsernav from "./Component/mobile/MobileUsernav";
import Footer from "./Component/footer/Footer";
import TawkTo from "tawkto-react";

import { useDispatch, useSelector } from "react-redux";
import {
  getgammemini,
  GetGeolocalisation,
  getsildes,
  GetUserBalance,
  getUSerDetails,
} from "./redux/actions/general";
import { getinitcasino } from "./redux/actions/Providers";
import {
  PUT_WINDROW_WIDTH,
  SET_NAV_BAR_CHANGE,
  SET_NAV_USER_CHANGE,
} from "./redux/actions/types";
import ReduxToastr from "react-redux-toastr";
import AccountMainV2 from "./Component/Account/AccountMainV2";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import ReactGA from "react-ga";
import ChangePassword from "./Component/Account/ChangePassword";
import Aviator from "./Component/Crash/Aviator";
import TVBET from "./Component/Crash/TVBET";
import Dino from "./Component/Crash/Dino";
import FireCrash from "./Component/Crash/FireCrash";
import Bridge from "./Component/Crash/Bridge";
import Penalty from "./Component/Crash/Penalty";
import SpinWIn from "./Component/Crash/SpinWIn";
import MiniGames from "./Component/mingames/MiniGames";
import MinigamesPlay from "./Component/mingames/MinigamesPlay";
import LiveCasinoV2 from "./Component/LiveCasinoV2/LiveCasinoV2";
import Login from "./Component/Login";

require("dotenv").config();

function App() {
  ReactGA.initialize("G-EP0LJK4X0K");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const dispatch = useDispatch();
  const { isloading } = useSelector((state) => state.Providers);
  const { userDetails, islogin, isloadinggeolocalisation } = useSelector(
    (state) => state.general
  );

  const hide_navbar = () => {
    var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)

    root.removeAttribute("class");

    dispatch({
      type: SET_NAV_USER_CHANGE,
      payload: false,
    });
  };
  const tawkToPropertyId = "623103eaa34c2456412b3c63";

  const tawkToKey = "1fu7nej1h";
 
  useEffect(() => {
    function handleResize() {
      dispatch({
        type: PUT_WINDROW_WIDTH,
        payload: window.innerWidth,
      });
    }
   
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    dispatch(getUSerDetails());
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);
  const timer = useRef(null);
  useEffect(() => {
    timer.current = setInterval(() => UpdateBalance(), 10000);
    document.addEventListener("touchstart", handeltouchmove, false);
    document.addEventListener("touchmove", handeltouchmoveend, false);
    return () => {
      clearInterval(timer.current);
    };
  }, [userDetails]);
  const handeltouchmove = (e) => {
    touchfirstposition = e.touches[0].clientX;
  };
  var touchfirstposition = 0;
  const handeltouchmoveend = (e) => {
    var te = e.changedTouches[0].clientX;
    if (te < touchfirstposition) {
      var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)

      root.removeAttribute("class");

      dispatch({
        type: SET_NAV_BAR_CHANGE,
        payload: false,
      });
    } else {
      var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)

      root.removeAttribute("class");

      dispatch({
        type: SET_NAV_USER_CHANGE,
        payload: false,
      });
    }
  };
  const UpdateBalance = () => {
    clearInterval(timer.current);
    if (JSON.parse(localStorage.getItem("token")) !== null) {
      dispatch(GetUserBalance());
    }

    timer.current = setInterval(() => UpdateBalance(), 10000);
  };

  return (
    <Suspense fallback="loading">
      <div id="confirmtransaction" />
      <MobileNavbar />
      <MobileUsernav></MobileUsernav>
      <div id="mm-0" className="mm-page mm-slideout">
        <div id="root">
          <div className="App">
            <MainHeader></MainHeader>
            {islogin === false ? (
              <Switch>
                <Route path={`/*`} render={(props) => <Login {...props} />} />
              </Switch>
            ) : userDetails.type_User < 5 ? (
              <Switch>
                <Route
                  exact
                  path={"/account"}
                  render={(props) => <AccountMainV2 {...props} />}
                />
                <Route
                  path={`/`}
                  render={(props) => <AccountMainV2 {...props} />}
                />
              </Switch>
            ) : (
              <Switch>
                <Route path={`/*`} render={(props) => <Login {...props} />} />
              </Switch>
            )}
          </div>
        </div>
      </div>
      
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Suspense>
  );
}

export default App;
