import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Updatepassword } from "../../redux/actions/ChangePassword";
import {
  INIT_CHANGE_PASSWORD,
  SET_CHANGE_PASSWORD_CONFIRME_P,
  SET_CHANGE_PASSWORD_NEW_P,
  SET_CHANGE_PASSWORD_OLD,
} from "../../redux/actions/types";

function ChangePassword() {
  const { oldpassword, newpassword, confirmpassword, insave } = useSelector(
    (state) => state.general
  );
  useEffect(() => {
    dispatch({ type: INIT_CHANGE_PASSWORD });
  }, []);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  return (
    <div ui-view="content" className="ng-scope">
      <section id="newUserContainer" className="ng-scope">
        <h3 translate="new_user" className="ng-isolate-scope">
          {t("account.part5")}
        </h3>
        <nvs-new-user-form className="ng-isolate-scope">
          <section id="newUserFormContainer">
            <div
              novalidate=""
              className="ng-pristine ng-invalid ng-invalid-required ng-valid-pattern ng-valid-minlength ng-valid-email"
              aria-hidden="false"
            >
              <div className="input-group-container">
                <div className="input-group-item">
                  <div>
                    <div>
                      <label translate="password" className="ng-isolate-scope">
                        {t("account.part25")}
                      </label>
                    </div>
                    <div className="input-with-icon">
                      <i class="fa fa-lock"></i>
                      <input
                        type="password"
                        value={oldpassword}
                        onChange={(el) =>
                          dispatch({
                            type: SET_CHANGE_PASSWORD_OLD,
                            payload: el.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched"
                      />
                    </div>
                  </div>
                </div>
                <div className="input-group-item">
                  <div>
                    <div>
                      <label
                        ng-translate=""
                        translate="username"
                        className="ng-isolate-scope"
                      >
                        {t("account.part26")}
                      </label>
                    </div>
                    <div className="input-with-icon">
                      <i class="fa fa-lock"></i>
                      <input
                        type="password"
                        value={newpassword}
                        onChange={(el) =>
                          dispatch({
                            type: SET_CHANGE_PASSWORD_NEW_P,
                            payload: el.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                      />
                    </div>
                  </div>
                </div>
                <div className="input-group-item">
                  <div>
                    <div>
                      <label
                        ng-translate=""
                        translate="username"
                        className="ng-isolate-scope"
                      >
                        {t("account.part27")}
                      </label>
                    </div>
                    <div className="input-with-icon">
                      <i class="fa fa-lock"></i>
                      <input
                        type="password"
                        value={confirmpassword}
                        onChange={(el) =>
                          dispatch({
                            type: SET_CHANGE_PASSWORD_CONFIRME_P,
                            payload: el.target.value,
                          })
                        }
                        className="form-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="input-group-container">
                <div className="input-group-item">
                  <button
                    onClick={() => dispatch(Updatepassword())}
                    type="button"
                    disabled={insave === true ? "disabled" : ""}
                    class={
                      insave === true
                        ? "button button-primary w-100 ng-isolate-scope btn-loading"
                        : "button button-primary w-100 ng-isolate-scope"
                    }
                    translate="Save"
                  >
                    {t("account.part24")}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </nvs-new-user-form>
      </section>
    </div>
  );
}
export default ChangePassword;
