import { Link, useLocation } from "react-router-dom";

function HeaderItems({ path, lib }) {
  const location = useLocation();

  return (
    <li className="nav-item">
      <Link
        to={path}
        className={location.pathname === path ? "nav-link active" : "nav-link"}
        title={lib}
      >
        {lib}{" "}
      </Link>
    </li>
  );
}
export default HeaderItems;
