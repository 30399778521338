import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./ProfileSidebar.css";
import ProfileSlidebarItem from "./ProfileSlidebarItem";
function ProfileSidebar() {
  const { userDetails } = useSelector((state) => state.general);
  const { t } = useTranslation();
  if (userDetails === {}) {
    return null;
  }
  return (
    <div className="profile-sidebar">
      <div className="nvs-sidebar-box ng-scope" ui-view="left">
        <div className="ng-scope ng-isolate-scope">
          <section id="nvsProfileSidebarContainer" className="ng-scope">
            <div className="nvs-profile-sidebar--content ng-scope">
              <h4 className="nvs-profile-sidebar--content--header ng-isolate-scope">
                {t("account.part3")}
              </h4>
              <ul className="nvs-profile-sidebar--content--menu">
                <ProfileSlidebarItem url="/account" lib={t("account.part4")} />
                <ProfileSlidebarItem
                  url="/account/password"
                  lib={t("account.part5")}
                />
              </ul>
            </div>

            {userDetails.type_User < 5 ? (
              <div
                class="nvs-profile-sidebar--content ng-scope"
                ng-repeat="menu in vmProfileSideBar.menus"
              >
                <h4
                  class="nvs-profile-sidebar--content--header ng-isolate-scope"
                  ng-translate=""
                  translate="affiliate_account"
                >
                  {t("account.part6")}
                </h4>
                <ul class="nvs-profile-sidebar--content--menu">
                  <ProfileSlidebarItem
                    url="/account/transaction"
                    lib={t("account.part13")}
                  />

                  {/* <ProfileSlidebarItem
                    url="/account/reportaccount"
                    lib={t("account.part9")}
                 />*/}
                  <ProfileSlidebarItem
                    url="/account/Transactionfinance"
                    lib={t("Transaction financière")}
                  />
                  {
                    <ProfileSlidebarItem
                      url="/account/reportrevenus"
                      lib={t("account.part10")}
                    />
                  }
                </ul>
              </div>
            ) : null}
           
          </section>
        </div>
      </div>
    </div>
  );
}
export default ProfileSidebar;
